import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import { buildSpinner, bytesToSize, flashNow } from "./helpers";

// Connects to data-controller="direct-upload"
export default class extends Controller {
  static targets = [
    "button", // upload button/label
    "hiddenField", // hidden field that stores the blob id
    "input", // file input
    "preview", // preview container where image is displayed
    "progress", // progress bar
    "removeInput", // hidden input, when set to "1", removes the file
    "submit", // submit button
  ];

  static values = {
    maxFileSize: { type: Number, default: 0 },
    removable: { type: Boolean, default: false },
    fileAddedEvent: { type: String, default: "direct-upload:fileAdded" },
    fileRemovedEvent: { type: String, default: "direct-upload:fileRemoved" },
  };

  connect() {
    this.acceptedFileTypes = this.inputTarget.accept.split(",");
    this.originalInputName = this.inputTarget.name;
    this.directUploadUrl = this.inputTarget.dataset.directUploadUrl;
    this.inputTarget.addEventListener("change", this.fileSelected);
    this.clearOriginalInput();
    this.setButtonStyle();
  }

  hasPreview() {
    return this.previewTarget.innerHTML.trim().length > 0;
  }

  clearOriginalInput() {
    this.inputTarget.value = "";
    this.inputTarget.name = "";
    this.inputTarget.removeAttribute("data-direct-upload-url");
  }

  setButtonStyle() {
    if (this.hasPreview()) {
      this.buttonTarget.classList.add("has-preview");
    } else {
      this.buttonTarget.classList.remove("has-preview");
    }
  }

  selectedFile() {
    return this.inputTarget.files[0];
  }

  notAcceptedFileType() {
    return !this.acceptedFileTypes.includes(this.selectedFile().type);
  }

  exceedsMaxFileSize() {
    return (
      this.maxFileSizeValue > 0 &&
      this.selectedFile().size > this.maxFileSizeValue
    );
  }

  validateFile() {
    if (!this.selectedFile()) {
      flashNow("alert", "No file selected.");
      return false;
    }

    if (this.notAcceptedFileType()) {
      flashNow("alert", "File type not supported.");
      return false;
    }

    if (this.exceedsMaxFileSize()) {
      const readableSize = bytesToSize(this.maxFileSizeValue);
      flashNow("alert", `File size must not exceed ${readableSize}.`);
      return false;
    }

    return true;
  }

  fileSelected = (event) => {
    if (!this.validateFile()) return;

    this.displayPreview();
    this.uploadFile();

    if (this.removableValue) {
      this.inputTarget.value = "";
    }
  };

  buildRemoveButton() {
    const button = document.createElement("button");
    button.setAttribute("type", "button");
    button.setAttribute("aria-label", "Remove image");
    button.setAttribute("data-action", "direct-upload#removeFile");

    const i = document.createElement("i");
    i.classList.add("fas", "fa-times");

    button.appendChild(i);

    return button;
  }

  buildRemovablePreview(img) {
    const div = document.createElement("div");
    const button = this.buildRemoveButton();
    const spinner = buildSpinner();
    div.classList.add("removable-upload-preview");

    div.appendChild(img);
    div.appendChild(button);
    div.appendChild(spinner);

    return div;
  }

  buildPreview(src) {
    const img = document.createElement("img");
    img.setAttribute("data-action", "click->direct-upload#openFileDialog");
    img.classList.add("upload-preview-image");
    img.src = src;
    img.alt = "Preview";

    return img;
  }

  appendPreview(event) {
    const src = event.target.result;
    const img = this.buildPreview(src);
    let preview;

    if (this.removableValue) {
      preview = this.buildRemovablePreview(img);
    } else {
      preview = img;
    }

    this.previewTarget.innerHTML = "";
    this.previewTarget.appendChild(preview);
  }

  displayPreview() {
    const reader = new FileReader();
    reader.onload = (event) => this.appendPreview(event);
    reader.readAsDataURL(this.selectedFile());
  }

  uploadFile() {
    const directUploadUrl = this.directUploadUrl;
    const upload = new DirectUpload(this.selectedFile(), directUploadUrl, this);
    this.fireEvent("direct-upload:start");

    upload.create((error, blob) => {
      this.setButtonStyle();
      this.progressTarget.style.width = 0;
      this.element.classList.remove("uploading");

      if (error) {
        console.error("Direct upload error:", error);
        flashNow("alert", "File upload failed.");
        return;
      }

      this.createHiddenBlobField(blob);
      this.fireEvent("direct-upload:end");
      this.fireEvent(this.fileAddedEventValue);
    });
  }

  fireEvent(eventName) {
    const form = this.inputTarget.form;
    const event = new CustomEvent(eventName, { bubbles: true });
    form.dispatchEvent(event);
  }

  removeHiddenBlobField() {
    if (!this.hasHiddenFieldTarget) {
      return;
    }

    this.hiddenFieldTarget.remove();
  }

  setRemoveInputValue(value) {
    if (!this.hasRemoveInputTarget) {
      return;
    }

    this.removeInputTarget.value = value;
  }

  createHiddenBlobField(blob) {
    this.removeHiddenBlobField();
    this.setRemoveInputValue(0);

    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = this.originalInputName;
    hiddenField.value = blob.signed_id;
    hiddenField.setAttribute("data-direct-upload-id", blob.id);
    hiddenField.setAttribute("data-direct-upload-target", "hiddenField");
    this.element.appendChild(hiddenField);
    this.element.setAttribute("data-direct-upload-complete", "true");
  }

  directUploadWillStoreFileWithXHR(request) {
    this.element.classList.add("uploading");
    request.upload.addEventListener("progress", this.directUploadDidProgress);
  }

  directUploadDidProgress = (progress) => {
    const percent = Math.round((progress.loaded / progress.total) * 100);
    this.progressTarget.style.width = `${percent}%`;
  };

  openFileDialog() {
    this.inputTarget.click();
  }

  removeFile() {
    this.previewTarget.innerHTML = "";
    this.removeHiddenBlobField();
    this.setRemoveInputValue(1);
    this.fireEvent(this.fileRemovedEventValue);
  }
}
