import { Controller } from "@hotwired/stimulus";
import { flashNow } from "./helpers";

// Connects to data-controller="turbo-skeleton"
export default class extends Controller {
  connect() {
    this.initialHTML = this.element.innerHTML;
    this.skeletonHTML = this.data.get("html") || this.initialHTML;

    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.renderSkeleton.bind(this)
    );

    this.element.addEventListener(
      "turbo:fetch-request-error",
      this.handleTurboError.bind(this)
    );

    this.element.addEventListener(
      "turbo:frame-render",
      this.handleTurboLoad.bind(this)
    );
  }

  renderSkeleton(event) {
    if (event.target !== this.element) return;

    this.element.innerHTML = this.skeletonHTML;
  }

  handleTurboError(event) {
    this.element.innerHTML = this.initialHTML;
    flashNow("error", "Something went wrong.");
  }

  handleTurboLoad(event) {
    const response = event.detail.fetchResponse.response;
    if (response.ok && !response.redirected) return;

    this.handleTurboError(event);
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-fetch-request",
      this.renderSkeleton
    );
    this.element.removeEventListener(
      "turbo:fetch-request-error",
      this.handleTurboError
    );
    this.element.removeEventListener(
      "turbo:frame-render",
      this.handleTurboLoad
    );
  }
}
