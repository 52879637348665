import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="image-modal"
export default class extends Controller {
  static values = {
    appendTo: { type: String, default: "body" },
    imageWrapperClass: { type: String, default: "image-wrapper" },
    fullSizeUrl: String,
  };

  connect() {
    this.src = this.fullSizeUrlValue || this.element.getAttribute("src");
    this.alt = this.element.getAttribute("alt") || "Full screen image";
    this.element.addEventListener("click", this.onImageClick);
    this.element.setAttribute("role", "button");
  }

  disconnect() {
    this.element.removeEventListener("click", this.onImageClick);
    window.removeEventListener("keyup", this.onWindowKeyup);
  }

  onImageClick = (event) => {
    event.preventDefault();
    this.imageModal = this.buildImageModal();
    this.appendImageModal(this.imageModal);
  };

  onModalClick = (event) => {
    this.fadeOutImageModal();
  };

  onWindowKeyup = (event) => {
    if (event.code !== "Escape") return;

    this.fadeOutImageModal();
  };

  onFadeInEnd = (event) => {
    window.addEventListener("keyup", this.onWindowKeyup);
    event.target.classList.remove("animate__fadeIn");
    event.target.removeEventListener("animationend", this.onFadeInEnd);
    event.target.focus();
  };

  onFadeOutEnd = (event) => {
    this.imageModal.removeEventListener("animationend", this.onFadeOutEnd);
    this.imageModal.remove();
    this.imageModal = null;
  };

  buildModal() {
    const modal = document.createElement("div");
    modal.classList.add("image-modal", "animate__animated", "animate__fadeIn");
    modal.setAttribute("role", "dialog");
    modal.setAttribute("aria-label", this.alt);
    modal.setAttribute("tabindex", "-1");
    modal.addEventListener("click", this.onModalClick);
    modal.addEventListener("animationend", this.onFadeInEnd);

    return modal;
  }

  buildImage() {
    const wrapper = document.createElement("div");
    wrapper.classList.add(this.imageWrapperClassValue);
    const image = document.createElement("img");
    image.setAttribute("src", this.src);
    image.setAttribute("alt", this.alt);
    wrapper.appendChild(image);

    return wrapper;
  }

  buildImageModal() {
    const modal = this.buildModal();
    const image = this.buildImage();
    modal.appendChild(image);

    return modal;
  }

  appendImageModal(imageModal) {
    let appendTo = document.querySelector(this.appendToValue);

    if (!appendTo) {
      appendTo = document.querySelector("body");
    }

    appendTo.appendChild(imageModal);
  }

  fadeOutImageModal() {
    if (!this.imageModal) return;

    this.imageModal.addEventListener("animationend", this.onFadeOutEnd);
    this.imageModal.removeEventListener("click", this.onModalClick);
    this.imageModal.classList.add("animate__fadeOut");
    window.removeEventListener("keyup", this.onWindowKeyup);
  }
}
