import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    this.duration = this.data.get("duration") || 2500;

    this.element.addEventListener("mouseenter", this.clearTimeout);
    this.element.addEventListener("mouseleave", this.animateAndRemove);
    this.startFadeOut();
  }

  startFadeOut = () => {
    this.clearTimeout();
    this.timeout = setTimeout(() => {
      this.animateAndRemove();
    }, this.duration);
  };

  animateAndRemove = () => {
    this.element.classList.add("animate__fadeOut");
    this.element.addEventListener("animationend", this.remove);
  };

  clearTimeout = () => {
    if (!this.timeout) {
      return;
    }

    clearTimeout(this.timeout);
    this.timeout = null;
  };

  remove = () => {
    this.element.remove();
  };

  disconnect() {
    this.clearTimeout();
    this.element.removeEventListener("mouseenter", this.pauseFadeOut);
    this.element.removeEventListener("mouseleave", this.startFadeOut);
  }
}
