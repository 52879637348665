import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { SCREEN_MD } from "./breakpoints";

// Connects to data-controller="theme-rating-field"
export default class extends Controller {
  static targets = [
    "body",
    "tick",
    "label",
    "range",
    "ratingInput",
    "textarea",
    "toggleButton",
    "toggleCheckbox",
    "ratingInputDelete",
    "textareaDelete",
  ];

  connect() {
    this.rangeMax = this.rangeTarget.max;
    this.originalTextareaName = this.textareaTarget.name;
    this.originalRatingInputName = this.ratingInputTarget.name;
    this.defaultTickPosition = parseInt(this.data.get("defaultTickPosition"));
    this.defaultRatingValue = parseFloat(this.data.get("defaultRatingValue"));
    this.handleWindowResize = throttle(this.handleWindowResize.bind(this), 100);
    window.addEventListener("resize", this.handleWindowResize);
    this.checkInitialValues();
    this.handleWindowResize();
  }

  checkInitialValues() {
    if (this.hasValues()) {
      this.updateFromRealValue();
      this.toggleBody();
      this.enable();
      return;
    }
    this.disable();
  }

  enabled() {
    return this.toggleCheckboxTarget.checked;
  }

  handleWindowResize() {
    const screenWidth = window.innerWidth;

    if (screenWidth < SCREEN_MD) {
      this.toggleButtonTarget.disabled = false;
      return;
    }

    this.toggleButtonTarget.setAttribute("aria-expanded", true);
    this.toggleButtonTarget.disabled = true;
  }

  toggleBody() {
    const ariaExpanded =
      this.toggleButtonTarget.getAttribute("aria-expanded") === "true";
    this.toggleButtonTarget.setAttribute("aria-expanded", !ariaExpanded);
  }

  onRangeChange(event) {
    const inputValue = event.target.value;
    const realValue = inputValue / this.rangeMax; // 0.0 - 1.0
    this.highlightTicks(inputValue);
    this.highlightLabels(realValue);
    this.ratingInputTarget.value = parseFloat(realValue).toFixed(2);

    if (this.enabled()) return;
    this.enable();
  }

  highlightTicks(inputValue) {
    const selectedTick = parseInt(inputValue);

    this.tickTargets.forEach((tick, index) => {
      if (this.shouldHighlightTick(index, selectedTick)) {
        tick.classList.add("active");
      } else {
        tick.classList.remove("active");
      }
    });
  }

  shouldHighlightTick(index, selectedTick) {
    const initialTickIndex = this.defaultTickPosition;
    const isSelectedTick = index === selectedTick;
    const isInitialTickIndex = index === initialTickIndex;
    const isBetweenTickedAndInitial =
      (index > initialTickIndex && index < selectedTick) ||
      (index < initialTickIndex && index > selectedTick);

    return isSelectedTick || isInitialTickIndex || isBetweenTickedAndInitial;
  }

  highlightLabels(realValue) {
    this.labelTargets.forEach((label) => {
      const minValue = parseFloat(label.getAttribute("data-min-real-value"));
      const maxValue = parseFloat(label.getAttribute("data-max-real-value"));
      if (realValue >= minValue && realValue <= maxValue) {
        label.classList.add("active");
        return;
      }

      label.classList.remove("active");
    });
  }

  selectTick(event) {
    const tick = event.target;
    const tickIndex = this.tickTargets.indexOf(tick);
    this.rangeTarget.value = tickIndex;
    this.updateRangeValue(tickIndex);
  }

  selectLabel(event) {
    const label = event.target;
    const value = parseInt(label.getAttribute("data-click-value"));
    this.updateRangeValue(value);
  }

  updateRangeValue(newValue) {
    this.rangeTarget.value = newValue;
    this.rangeTarget.dispatchEvent(new Event("input"));
  }

  onTextareaChange(event) {
    const trimmedValue = event.target.value.trim();
    if (trimmedValue.length === 0) return;

    this.enable();
  }

  onCheckboxChange(event) {
    if (event.target.checked) {
      this.enable();
      return;
    }

    this.disable();
  }

  hasValues() {
    return (
      this.textareaTarget.value.trim().length > 0 ||
      this.ratingInputTarget.value > this.defaultRatingValue ||
      this.ratingInputTarget.value < this.defaultRatingValue
    );
  }

  updateFromRealValue() {
    const realValue = parseFloat(this.ratingInputTarget.value);
    const rangeValue = realValue * this.rangeMax;
    this.updateRangeValue(rangeValue);
  }

  enable() {
    this.toggleCheckboxTarget.checked = true;
    this.textareaTarget.name = this.originalTextareaName;
    this.ratingInputTarget.name = this.originalRatingInputName;
    this.textareaDeleteTarget.disabled = true;
    this.ratingInputDeleteTarget.disabled = true;
    this.element.classList.add("active");
    this.toggleCheckboxTarget.dispatchEvent(new Event("change"));
    this.rangeTarget.dispatchEvent(new Event("input"));
  }

  disable() {
    // Remove input names to prevent them from being submitted
    this.textareaTarget.name = "";
    this.ratingInputTarget.name = "";
    this.textareaDeleteTarget.disabled = false;
    this.ratingInputDeleteTarget.disabled = false;
    this.removeActiveClasses();
  }

  removeActiveClasses() {
    this.tickTargets.forEach((tick) => tick.classList.remove("active"));
    this.labelTargets.forEach((label) => label.classList.remove("active"));
    this.element.classList.remove("active");
  }

  disconnect() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
}
