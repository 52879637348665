import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="listbox"
export default class extends Controller {
  static targets = ["button", "menu", "option", "hiddenInput"];

  static values = {
    maxRows: { type: Number, default: 0 }, // The maximum number of rows to show in the menu
    setMinWidth: { type: Boolean, default: false }, // Set the button's min-width to the width of the longest option
    submitOnChange: { type: Boolean, default: false }, // Submit the form when the value changes
  };

  connect() {
    if (this.setMinWidthValue) {
      this.setMinWidth();
      window.addEventListener("orientationchange", this.setMinWidth);
    }

    if (this.maxRowsValue > 0) {
      this.setMenuMaxHeight();
      window.addEventListener("orientationchange", this.setMenuMaxHeight);
    }

    this.setSelectedOptionFromValue();
    this.element.setAttribute("data-listbox-initialized", true);
  }

  disconnect() {
    if (this.setMinWidthValue) {
      window.removeEventListener("orientationchange", this.setMinWidth);
    }

    if (this.maxRowsValue > 0) {
      window.removeEventListener("orientationchange", this.setMenuMaxHeight);
    }
  }

  isExpanded() {
    return this.buttonTarget.getAttribute("aria-expanded") === "true";
  }

  selectedOption() {
    return this.optionTargets.find((option) => {
      return option.getAttribute("aria-selected") === "true";
    });
  }

  getLongestOption() {
    return this.optionTargets.reduce((longest, option) => {
      return option.innerText.length > longest.innerText.length
        ? option
        : longest;
    });
  }

  setMinWidth = () => {
    const longestOption = this.getLongestOption();
    const initialButtonContent = this.buttonTarget.innerText;
    this.buttonTarget.innerText = longestOption.innerText;
    this.buttonTarget.style.minWidth = longestOption.offsetWidth + "px";
    this.buttonTarget.style.maxWidth = longestOption.offsetWidth + "px";
    this.buttonTarget.innerText = initialButtonContent;
  };

  setMenuMaxHeight = () => {
    const buttonHeight = this.buttonTarget.offsetHeight; // Options are the same height as the button
    const halfButtonHeight = buttonHeight / 2; // Add half to indicate there are more options available
    const maxMenuHeight = buttonHeight * this.maxRowsValue + halfButtonHeight;
    this.menuTarget.style.maxHeight = maxMenuHeight + "px";
  };

  toggle() {
    this.buttonTarget.setAttribute("aria-expanded", !this.isExpanded());
  }

  hide() {
    this.buttonTarget.setAttribute("aria-expanded", false);
    setTimeout(() => {
      this.buttonTarget.focus();
    });
  }

  show() {
    this.buttonTarget.setAttribute("aria-expanded", true);
  }

  handleClick(event) {
    if (this.isExpanded() && !this.element.contains(event.target)) {
      this.hide();
    }
  }

  handleButtonKeydown(event) {
    const key = event.key;

    switch (key) {
      case "ArrowDown":
      case "ArrowUp":
        event.preventDefault();
        this.show();
        this.focusFirstOption();
        break;
      case "Escape":
        this.hide();
        break;
    }
  }

  handleOptionKeydown(event) {
    const key = event.key;

    switch (key) {
      case "Enter":
        this.select(event);
        break;
      case "ArrowDown":
        event.preventDefault();
        this.focusNextOption(event);
        break;
      case "ArrowUp":
        event.preventDefault();
        this.focusPreviousOption(event);
        break;
      case "Escape":
        this.hide();
        break;
    }
  }

  handleOptionBlur(event) {
    if (this.element.contains(event.relatedTarget)) return;
    this.hide();
  }

  select(event) {
    const option = event.currentTarget;
    const selectedOption = this.selectedOption();

    if (selectedOption) {
      selectedOption.setAttribute("aria-selected", false);
    }

    option.setAttribute("aria-selected", true);
    this.buttonTarget.innerText = option.innerText;
    this.updateHiddenInput(option);
    this.hide();
  }

  setSelectedOptionFromValue() {
    if (!this.hasHiddenInputTarget) return;

    const option = this.optionTargets.find((option) => {
      return option.getAttribute("data-value") === this.hiddenInputTarget.value;
    });

    if (!option) return;

    option.setAttribute("aria-selected", true);
    this.buttonTarget.innerText = option.innerText;
  }

  updateHiddenInput(option) {
    if (!this.hasHiddenInputTarget) return;

    this.hiddenInputTarget.value = option.getAttribute("data-value");
    this.hiddenInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
    this.hiddenInputTarget.dispatchEvent(
      new Event("change", { bubbles: true })
    );

    if (!this.submitOnChangeValue) return;
    this.requestSubmit();
  }

  requestSubmit() {
    const form = this.hiddenInputTarget.form;

    if (!form) {
      console.warn(
        "The listbox's hidden input must be inside a form element to submit."
      );
      return;
    }

    form.requestSubmit();
  }

  focusFirstOption() {
    this.optionTargets[0].focus();
  }

  focusNextOption(event) {
    const option = event.currentTarget;
    const nextOption = option.nextElementSibling;

    if (nextOption) {
      nextOption.focus();
    } else {
      this.focusFirstOption();
    }
  }

  focusPreviousOption(event) {
    const option = event.currentTarget;
    const previousOption = option.previousElementSibling;

    if (previousOption) {
      previousOption.focus();
    } else {
      this.optionTargets[this.optionTargets.length - 1].focus();
    }
  }
}
