import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="fixed-header"
export default class extends Controller {
  static targets = ["header", "scrollable"];

  connect() {
    this.header = this.hasHeaderTarget ? this.headerTarget : this.element;
    this.scrollable = this.hasScrollableTarget ? this.scrollableTarget : window;

    this.handleScroll();
    this.scrollable.addEventListener(
      "scroll",
      throttle(this.handleScroll.bind(this), 250)
    );
  }

  handleScroll() {
    const threshold = parseInt(this.data.get("threshold") || 25);
    const scrollPosition = this.hasScrollableTarget
      ? this.scrollable.scrollTop
      : this.scrollable.scrollY;

    if (scrollPosition > threshold) {
      this.header.classList.add("active");
    } else {
      this.header.classList.remove("active");
    }
  }

  disconnect() {
    this.scrollable.removeEventListener("scroll", this.handleScroll);
  }
}
