// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails";
import "./controllers";
import * as ActiveStorage from "@rails/activestorage";
import { setCookie } from "./helpers";
import LocalTime from "local-time";
import jstz from "jstz";

ActiveStorage.start();
LocalTime.start();

const timezone = jstz.determine();

setCookie("timezone", timezone.name());
