import { Controller } from "@hotwired/stimulus";
import { getMetaContent, buildFlash } from "./helpers";

// Connects to data-controller="notifications"
export default class extends Controller {
  static values = {
    markAllReadPath: String, // The path to mark all notifications as read
  };

  static targets = [
    "notification", // Each notification in the list
  ];

  connect() {
    this.sideBarNotificationCount = document.getElementById("sidebar-notification-count");
  }

  getUnreadNotifications() {
    return this.notificationTargets.filter((target) => target.classList.contains("new-notification"));
  }

  styleNotificationsAsRead(notifications) {
    notifications.forEach((target) => {
      target.classList.remove("new-notification");
      target.removeAttribute("data-controller");
    });
  }

  styleNotificationsAsUnread(notifications) {
    notifications.forEach((target) => {
      target.classList.add("new-notification");
      target.setAttribute("data-controller", "class-toggle");
    });
  }

  async requestMarkAllRead() {
    const response = await fetch(this.markAllReadPathValue, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
    });

    return response;
  }

  async markAllRead() {
    const unreadNotifications = this.getUnreadNotifications();
    this.styleNotificationsAsRead(unreadNotifications);
    this.sideBarNotificationCount.classList.add("!hidden");
    const response = await this.requestMarkAllRead();

    if (!response.ok) {
      buildFlash("alert", "Something went wrong, please try again.");
      this.styleNotificationsAsUnread(unreadNotifications);
      this.sideBarNotificationCount.classList.remove("!hidden");
      return;
    }

    this.element.setAttribute("data-marked-all-read", true);
  }
}
