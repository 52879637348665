import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

// Connects to forms with data-controller="turbo-filter"
export default class extends Controller {
  static values = {
    disabled: Boolean,
    debounce: { type: Number, default: 250 },
  };

  connect() {
    this.onInput = debounce(this.onInput, this.debounceValue);

    this.element.addEventListener("change", this.onChange);
    this.element.addEventListener("input", this.onInput);
  }

  onChange = (event) => {
    this.requestSubmit();
  };

  onInput = (event) => {
    this.requestSubmit();
  };

  requestSubmit() {
    if (this.disabledValue) {
      return;
    }

    this.element.requestSubmit();
  }
}
