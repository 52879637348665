import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="remove-animated"
export default class extends Controller {
  connect() {
    this.element.addEventListener("animationend", this.removeAnimatedClass);
  }

  removeAnimatedClass = () => {
    this.element.classList.remove("animate__animated");
  };
}
