import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="edit-profile"
export default class extends Controller {
  static values = {
    artworkModalId: { type: String, default: "artwork-selection-modal" }, // The ID of the artwork selection modal
    plansPath: String, // The path to the plans page
  };

  static targets = [
    "backgroundImage", // The profile's background image element
    "backgroundInput", // The hidden input that stores the background image ID
    "bannerImage", // The profile's banner image element
    "bannerInput", // The hidden input that stores the banner image ID
    "removeCustomBannerInput", // The hidden input used to remove the custom banner
  ];

  selectBanner(event) {
    const {
      dataset: { bannerUrl, bannerId },
    } = event.currentTarget;

    this.bannerImageTarget.src = bannerUrl;
    this.bannerInputTarget.value = bannerId;
    this.removeCustomBannerInputTarget.value = "1";
  }

  selectBackground(event) {
    const {
      dataset: { backgroundUrl, backgroundId },
    } = event.currentTarget;

    if (backgroundUrl && backgroundId) {
      this.backgroundImageTarget.style.backgroundImage = `url(${backgroundUrl})`;
      this.backgroundInputTarget.value = backgroundId;
      this.element.classList.add("profile-page--themed");
      return;
    }

    this.backgroundImageTarget.style.backgroundImage = "none";
    this.backgroundInputTarget.value = "";
    this.element.classList.remove("profile-page--themed");
  }

  fireCloseModalEvent() {
    const modalId = this.artworkModalIdValue;

    const customEvent = new CustomEvent("modal:close", {
      detail: { modalId },
      bubbles: true,
    });

    this.element.dispatchEvent(customEvent);
  }

  selectArtwork(event) {
    const { artworkType, artworkLocked } = event.currentTarget.dataset;

    if (artworkLocked === "true") {
      Turbo.visit(this.plansPathValue);
      return;
    }

    switch (artworkType) {
      case "banner":
        this.selectBanner(event);
        break;
      case "background":
        this.selectBackground(event);
        break;
    }

    this.fireCloseModalEvent();
  }
}
