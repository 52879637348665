import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { buildSpinner } from "./helpers";

// Connects to data-controller="form"
export default class extends Controller {
  static values = {
    skipInitialValidation: Boolean,
  };

  static targets = [
    "requiredInput", // A textarea or input[type=text] that is required
    "requiredRadioGroup", // An element containing a group of radio buttons that requires selection
    "submitButton", // The submit button that gets enabled/disabled and receives a loading state
  ];

  connect() {
    // redirect-on-success attribute should be set to true if the form
    // should handle errors via turbo frames but perform a redirect on success
    this.redirectOnSuccess = this.data.get("redirect-on-success") === "true";
    this.loadingButtonText = this.submitButtonTarget.getAttribute("data-loading-text") || "";
    this.submitButtonContentElement = this.submitButtonTarget.firstElementChild || this.submitButtonTarget;
    this.initialSubmitButtonText = this.submitButtonContentElement.innerText;
    this.initialSubmitButtonWidth = this.submitButtonTarget.offsetWidth;
    this.initialSubmitButtonHeight = this.submitButtonTarget.offsetHeight;

    this.addEventListeners();
  }

  isTurboForm() {
    return this.element.getAttribute("data-turbo") !== "false";
  }

  addEventListeners() {
    const form = this.element;

    form.addEventListener("direct-upload:start", this.onDirectUploadStart);
    form.addEventListener("direct-upload:end", this.onDirectUploadEnd);

    if (!this.isTurboForm()) {
      form.addEventListener("submit", this.onSubmitStart);
      return;
    }

    form.addEventListener("turbo:submit-start", this.onSubmitStart);
    form.addEventListener("turbo:submit-end", this.onSubmitEnd);
  }

  onSubmitStart = (event) => {
    this.submitButtonTarget.disabled = true;
    this.showSubmitSpinner();
  };

  onSubmitEnd = (event) => {
    this.submitButtonTarget.disabled = false;
    this.removeSubmitSpinner();

    if (event.detail.success && this.redirectOnSuccess) {
      this.redirectToSuccessUrl(event.detail.fetchResponse);
    }
  };

  onDirectUploadStart = (event) => {
    this.submitButtonTarget.disabled = true;
  };

  onDirectUploadEnd = (event) => {
    this.checkFormValidity();
  };

  redirectToSuccessUrl(fetchResponse) {
    history.pushState({ turbo_frame_history: true }, "", fetchResponse.response.url);

    Turbo.visit(fetchResponse.response.url);
  }

  showSubmitSpinner() {
    this.spinner = buildSpinner();
    this.submitButtonTarget.classList.add("loading");
    this.submitButtonContentElement.innerText = this.loadingButtonText;
    this.submitButtonTarget.style.minWidth = `${this.initialSubmitButtonWidth}px`;
    this.submitButtonTarget.style.minHeight = `${this.initialSubmitButtonHeight}px`;
    this.submitButtonContentElement.prepend(this.spinner);
  }

  removeSubmitSpinner() {
    this.submitButtonTarget.classList.remove("loading");
    this.spinner.remove();
    this.submitButtonContentElement.innerText = this.initialSubmitButtonText;
    this.submitButtonTarget.style.minWidth = "";
    this.submitButtonTarget.style.minHeight = "";
  }

  isRadioGroupValid(element) {
    const radios = element.querySelectorAll("input[type=radio]");

    return Array.from(radios).some((radio) => {
      return radio.checked;
    });
  }

  isTextInputValid(element) {
    return element.value.trim() !== "";
  }

  validateRadioGroups() {
    return this.requiredRadioGroupTargets.every((group) => {
      return this.isRadioGroupValid(group);
    });
  }

  validateTextInputs() {
    return this.requiredInputTargets.every((input) => {
      return this.isTextInputValid(input);
    });
  }

  requiredInputsCompleted() {
    const radioGroupsValid = this.validateRadioGroups();
    const textInputsValid = this.validateTextInputs();

    return radioGroupsValid && textInputsValid;
  }

  checkFormValidity() {
    const formReady = this.requiredInputsCompleted();

    this.submitButtonTarget.disabled = !formReady;
    this.element.classList.toggle("submit-disabled", !formReady);
  }

  requiredRadioGroupTargetConnected(element) {
    if (this.skipInitialValidationValue) {
      return;
    }

    this.checkFormValidity();
  }

  requiredRadioGroupTargetDisconnected(element) {
    this.checkFormValidity();
  }

  requiredInputTargetConnected(element) {
    if (this.skipInitialValidationValue) {
      return;
    }

    this.checkFormValidity();
  }

  requiredInputTargetDisconnected(element) {
    this.checkFormValidity();
  }
}
