import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="verdict-rating-field"
export default class extends Controller {
  static targets = ["label", "scale", "ratingInput", "range", "percentage"];

  connect() {
    this.maxRange = this.rangeTarget.max;
    this.initializeExistingValue();
  }

  initializeExistingValue() {
    const existingRealValue = this.ratingInputTarget.value;

    if (!existingRealValue) return;

    const pseudoValue = existingRealValue * this.maxRange;
    this.rangeTarget.value = pseudoValue;
    this.rangeTarget.dispatchEvent(new Event("input"));
  }

  onRangeChange(e) {
    const inputValue = e.target.value;
    const realValue = inputValue / this.maxRange; // 0.0 - 1.0
    this.updateScaleClass(realValue);
    this.updatePercentage(realValue);
    this.toggleLabelClasses(realValue);
    this.updateRatingInput(realValue);
  }

  updateRatingInput(realValue) {
    this.ratingInputTarget.value = parseFloat(realValue).toFixed(2);
    this.ratingInputTarget.dispatchEvent(new Event("input"));
  }

  // Refer to the Flutter app - lib/mixins/widget_utils.dart#L99
  // Refer to the ReviewHelper - app/helpers/review_helper.rb#L4
  convertToLabelIndex(realValue) {
    let labelIndex;

    if (realValue < 0.2) {
      labelIndex = 0;
    } else if (realValue == 1) {
      labelIndex = 4;
    } else {
      labelIndex = Math.floor(realValue * 5);
    }

    return labelIndex;
  }

  updatePercentage(realValue) {
    const labelIndex = this.convertToLabelIndex(realValue);
    const newPercentage = realValue * 100;
    const newClass = `rating-percentage rating-percentage--${labelIndex}`;
    this.percentageTarget.innerText = `${newPercentage.toFixed(0)}%`;
    this.percentageTarget.className = newClass;
  }

  updateScaleClass(realValue) {
    const labelIndex = this.convertToLabelIndex(realValue);
    const newClass = `rating-scale rating-scale--${labelIndex}`;
    this.scaleTarget.className = newClass;
  }

  toggleLabelClasses(realValue) {
    this.labelTargets.forEach((label) => {
      const minLabelValue = parseFloat(label.dataset.minValue);
      const maxLabelValue = parseFloat(label.dataset.maxValue);

      if (realValue >= minLabelValue && realValue <= maxLabelValue) {
        label.classList.add("active");
        return;
      }

      label.classList.remove("active");
    });
  }

  onBarClick(e) {
    const barValue = e.target.dataset.value;
    this.rangeTarget.value = barValue;
    this.rangeTarget.dispatchEvent(new Event("input"));
  }
}
