import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

// Connects to data-controller="turbo-search"
export default class extends Controller {
  static values = {
    debounce: { type: Number, default: 250 }, // The number of milliseconds to wait before sending a search request
    frameId: String, // The id of the turbo-frame to fetch search results into
    minLength: { type: Number, default: 2 }, // The minimum length of the search query to send a request
    searchUrl: String, // The URL to send the search request to
    queryParam: { type: String, default: "query" }, // The name of the query parameter to send the search query as
  };

  connect() {
    this.search = debounce(this.search, this.debounceValue);
    this.element.addEventListener("input", this.search);
    this.turboFrame = document.getElementById(this.frameIdValue);
    this.initialFrameSrc = this.turboFrame.getAttribute("src");
  }

  disconnect() {
    this.element.removeEventListener("input", this.search);
  }

  search = (event) => {
    const query = event.target.value.trim();

    if (query.length === 0) {
      this.turboFrame.setAttribute("src", this.initialFrameSrc);
      return;
    } else if (query.length < this.minLengthValue) {
      return;
    }

    const url = new URL(this.searchUrlValue);
    url.searchParams.set(this.queryParamValue, query);

    this.turboFrame.setAttribute("src", url);
  };
}
