import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="send"
export default class extends Controller {
  static values = {
    url: String,
    copiedText: { type: String, default: "Copied!" },
  };

  static targets = ["copyButton"];

  connect() {
    this.initialCopyButtonText = this.copyButtonTarget.innerText;
  }

  openWindow(baseUrl) {
    const url = `${baseUrl}${encodeURIComponent(this.urlValue)}`;
    window.open(url, "popup", "width=600,height=600");
  }

  shareFacebook() {
    this.openWindow("https://www.facebook.com/sharer/sharer.php?u=");
  }

  shareTwitter() {
    this.openWindow("https://twitter.com/intent/tweet?text=");
  }

  shareReddit() {
    this.openWindow("https://www.reddit.com/submit?url=");
  }

  copyUrl() {
    navigator.clipboard.writeText(this.urlValue);
    this.copyButtonTarget.innerText = this.copiedTextValue;
    this.copyButtonTarget.classList.add("copied");

    setTimeout(() => {
      this.copyButtonTarget.innerText = this.initialCopyButtonText;
      this.copyButtonTarget.classList.remove("copied");
    }, 2000);
  }
}
