import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { buildSpinner } from "./helpers";

// Connects to data-controller="search-dropdown"
export default class extends Controller {
  static targets = [
    "input", // Search input
    "frame", // Turbo frame
    "results", // Immediate parent of results
    "result", // Each result
  ];

  static values = {
    url: String, // Full URL to fetch results from, excluding query param
    closeOnSelect: { type: Boolean, default: true }, // Closes dropdown on result select
    clearOnSelect: { type: Boolean, default: true }, // Clears input on result select
    param: { type: String, default: "query" }, // Query param name e.g. /search?query=foo
    minChars: { type: Number, default: 2 }, // Minimum characters to trigger search
  };

  connect() {
    this.initialFrameHTML = this.frameTarget.innerHTML;
    this.onInputChange = debounce(this.onInputChange.bind(this), 250);
    this.inputTarget.addEventListener("input", this.onInputChange);
    this.inputTarget.addEventListener("focus", this.onInputFocus);
    this.inputTarget.addEventListener("keydown", this.onInputKeydown);
    this.frameTarget.addEventListener("turbo:frame-load", this.onFrameLoad);
  }

  resultTargetConnected(element) {
    element.setAttribute("role", "option");
    element.setAttribute("tabindex", "-1");
    element.addEventListener("keydown", this.onResultKeydown);
  }

  isExpanded() {
    return this.inputTarget.getAttribute("aria-expanded") === "true";
  }

  onInputChange = (event) => {
    const query = event.target.value.trim();

    if (query.length === 0) {
      this.close();
      return;
    }

    if (query.length < this.minCharsValue) {
      return;
    }

    this.fetchResults(query);
  };

  fetchResults(query) {
    const url = new URL(this.urlValue);
    url.searchParams.set(this.paramValue, query);
    this.frameTarget.src = url;
    this.spinner = buildSpinner();
    this.inputTarget.after(this.spinner);
  }

  onInputFocus = (event) => {
    if (this.isExpanded()) return;

    this.onInputChange(event);
  };

  onInputKeydown = (event) => {
    if (!this.isExpanded()) return;

    const { key } = event;

    switch (key) {
      case "ArrowDown":
        event.preventDefault();
        this.focusFirstResult();
        break;
      case "Escape":
        this.close();
        break;
    }
  };

  onResultKeydown = (event) => {
    const key = event.key;

    switch (key) {
      case "Enter":
        event.preventDefault();
        this.selectResult(event);
        break;
      case "ArrowDown":
      case "ArrowRight":
        event.preventDefault();
        this.focusNextResult(event);
        break;
      case "ArrowUp":
      case "ArrowLeft":
        event.preventDefault();
        this.focusPreviousResult(event);
        break;
      case "Escape":
        this.close();
        break;
    }
  };

  selectResult = (event) => {
    const option = event.currentTarget;
    const child = option.firstElementChild;
    const { disabled } = option.dataset;

    if (disabled) return;

    if (child && ["A", "BUTTON"].includes(child.tagName)) {
      child.click();
    } else {
      option.click();
    }

    this.reset();
  };

  reset = () => {
    if (this.clearOnSelectValue) {
      this.inputTarget.value = "";
    }

    if (!this.closeOnSelectValue) return;

    this.close();
  };

  focusFirstResult = () => {
    this.resultsTarget.firstElementChild.focus();
  };

  focusNextResult = (event) => {
    const option = event.currentTarget;
    const nextResult = option.nextElementSibling;

    if (nextResult) {
      nextResult.focus();
    } else {
      this.focusFirstResult();
    }
  };

  focusPreviousResult = (event) => {
    const option = event.currentTarget;
    const previousResult = option.previousElementSibling;

    if (previousResult) {
      previousResult.focus();
    } else {
      this.inputTarget.focus();
    }
  };

  onFrameLoad = (event) => {
    if (event.target !== this.frameTarget) return;

    this.removeSpinner();

    if (this.isExpanded()) return;

    this.frameTarget.setAttribute("aria-live", "polite");
    this.inputTarget.setAttribute("aria-expanded", true);
    window.addEventListener("click", this.onWindowClick);
  };

  onWindowClick = (event) => {
    if (this.element.contains(event.target)) {
      return;
    }

    this.close();
  };

  close = () => {
    this.removeSpinner();
    this.inputTarget.setAttribute("aria-expanded", false);
    this.frameTarget.removeAttribute("src");
    this.frameTarget.removeAttribute("aria-live");
    window.removeEventListener("click", this.onWindowClick);

    setTimeout(() => {
      this.frameTarget.innerHTML = this.initialFrameHTML;
    });
  };

  removeSpinner() {
    this.element.querySelectorAll(".spinner-wrapper").forEach((spinner) => {
      spinner.remove();
    });
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.onInput);
    this.inputTarget.removeEventListener("focus", this.onInput);
    this.inputTarget.removeEventListener("keydown", this.onInputKeydown);
    this.frameTarget.removeEventListener("turbo:frame-load", this.onFrameLoad);
  }
}
