import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="discover"
export default class extends Controller {
  static targets = [
    "controls",
    "scrollable",
    "turboFrame",
    "previousButton",
    "miscItem",
    "nextButton",
    "titleCard",
    "titleCardImage",
  ];

  initialize() {
    this.observerRoot = this.hasScrollableTarget
      ? this.scrollableTarget
      : document;

    this.initializeVisibilityObserver();
    this.initializeLazyLoadObserver();
    this.element.addEventListener(
      "turbo:frame-load",
      this.onFrameLoad.bind(this)
    );
  }

  onFrameLoad(event) {
    this.displayControls();
    this.handleScroll();

    const turboFrame = event.target;
    turboFrame.replaceWith(...turboFrame.childNodes);
  }

  scrollableTargetConnected() {
    this.handleScroll = throttle(this.handleScroll.bind(this), 250);
    this.scrollableTarget.addEventListener("scroll", this.handleScroll);
    this.initializeScrollPosition();
  }

  scrollableTargetDisconnected() {
    this.scrollableTarget.removeEventListener("scroll", this.handleScroll);
  }

  initializeScrollPosition() {
    const scrollPosition = parseInt(this.element.dataset.scrollPosition);
    if (!scrollPosition) return;

    this.scrollableTarget.scrollLeft = scrollPosition;
  }

  initializeLazyLoadObserver() {
    const options = {
      root: this.observerRoot,
      rootMargin: this.data.get("lazy-root-margin") || "0px",
      threshold: parseFloat(this.data.get("lazy-threshold") || 0.1),
    };

    this.lazyLoadObserver = new IntersectionObserver(
      this.handleLazyLoadIntersection.bind(this),
      options
    );
  }

  initializeVisibilityObserver() {
    const options = {
      root: this.observerRoot,
      rootMargin: this.data.get("visibility-root-margin") || "0px",
      threshold: parseFloat(this.data.get("visibility-threshold") || 0.85),
    };

    this.visibilityObserver = new IntersectionObserver(
      this.handleVisibilityIntersection.bind(this),
      options
    );
  }

  // Observe images as they come into view to load them, then unobserve them
  handleLazyLoadIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setImageAndListenForLoad(entry.target);
        this.lazyLoadObserver.unobserve(entry.target);
      }
    });
  }

  // Swap the data-src attribute for the src attribute, then observe the title card
  setImageAndListenForLoad(image) {
    image.src = image.dataset.src;
    const titleCard = image.parentElement;

    image.addEventListener("load", () => {
      this.visibilityObserver.observe(titleCard);
      titleCard.classList.remove("is-loading");
    });
  }

  // Toggle classes to increase the opacity of the title card as it comes into view
  handleVisibilityIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("is-visible");
      } else {
        entry.target.classList.remove("is-visible");
      }
    });
  }

  titleCardImageTargetConnected(image) {
    this.lazyLoadObserver.observe(image);
  }

  miscItemTargetConnected(item) {
    this.visibilityObserver.observe(item);
  }

  miscItemTargetDisconnected(item) {
    this.visibilityObserver.unobserve(item);
  }

  displayControls() {
    if (!this.hasControlsTarget) return;

    this.controlsTarget.classList.remove("is-loading");
  }

  scrollLeft() {
    const newPosition =
      this.scrollableTarget.scrollLeft - this.scrollableTarget.offsetWidth;
    this.scrollTo(newPosition);
  }

  scrollRight() {
    const newPosition =
      this.scrollableTarget.scrollLeft + this.scrollableTarget.offsetWidth;
    this.scrollTo(newPosition);
  }

  scrollTo(newScrollLeft) {
    this.scrollableTarget.scroll({
      left: newScrollLeft,
      behavior: "smooth",
    });
  }

  handleScroll() {
    if (!this.hasScrollableTarget) return;

    const scrollPos = this.scrollableTarget.scrollLeft;
    const scrollWidth = this.scrollableTarget.scrollWidth;
    const elementWidth = this.scrollableTarget.offsetWidth;
    const threshold = 50;

    this.previousButtonTarget.disabled = scrollPos < threshold;
    this.nextButtonTarget.disabled =
      scrollPos >= scrollWidth - elementWidth - threshold;
    this.element.setAttribute("data-scroll-position", scrollPos);
  }

  disconnect() {
    this.visibilityObserver.disconnect();
    this.lazyLoadObserver.disconnect();
    this.element.removeEventListener("turbo:frame-load", this.onFrameLoad);
  }
}
