import FormController from "./form_controller";

export default class AccountDeletionFormController extends FormController {
  static values = {
    confirmText: { type: String, default: "DELETE" },
  };

  connect() {
    super.connect();
    this.submitButtonTarget.disabled = true;
  }

  deleteConfirmed(value) {
    return value === this.confirmTextValue;
  }

  inputChanged(event) {
    const { value } = event.target;
    this.submitButtonTarget.disabled = !this.deleteConfirmed(value);
  }
}
