import { SCREEN_DESKTOP_WIDTH, SCREEN_DESKTOP_HEIGHT } from "../breakpoints";

export function buildSpinner() {
  const spinnerWrapper = document.createElement("span");
  spinnerWrapper.className = "spinner-wrapper";
  const spinner = document.createElement("span");
  spinner.className = "spinner";
  spinnerWrapper.appendChild(spinner);
  return spinnerWrapper;
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

// Use same flash styling from app/views/application/_flash.html.erb
export function buildFlash(type, message) {
  const div = document.createElement("div");
  div.className = `flash-message ${type} animate__animated animate__fadeInDown`;
  div.setAttribute("data-controller", "flash");
  div.setAttribute("data-action", "click->flash#remove");

  const icon = document.createElement("i");
  const iconClass = type === "notice" ? "fa-circle-check" : "fa-circle-exclamation";
  icon.className = `fa-solid ${iconClass}`;
  div.appendChild(icon);

  const span = document.createElement("span");
  span.innerText = message;
  div.appendChild(span);

  return div;
}

// Mocks Rails flash.now
export function flashNow(type, message, options = {}) {
  const flashContainer = findElement("#flash");
  if (!flashContainer) return;

  const flashMessage = buildFlash(type, message);

  if (options.clearMessages) {
    flashContainer.innerHTML = "";
  }

  flashContainer.prepend(flashMessage);
}

export function getMetaContent(name) {
  const element = findElement(`meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function fetchWithCsrf(url, options = {}) {
  const defaults = {
    headers: {
      "X-CSRF-Token": getMetaContent("csrf-token"),
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  return fetch(url, { ...defaults, ...options });
}

export function isDesktop() {
  return window.innerWidth >= SCREEN_DESKTOP_WIDTH && window.innerHeight >= SCREEN_DESKTOP_HEIGHT;
}

export function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export function isTouchDevice() {
  return "ontouchstart" in window;
}

export function amountToCurrency(amount, options = {}) {
  const decimalAmount = amount / 100;

  const defaults = {
    unit: "$",
    precision: 2,
    delimiter: ",",
    separator: ".",
  };

  const settings = { ...defaults, ...options };

  const intPart = Math.floor(decimalAmount);
  let fracPart = (decimalAmount - intPart).toFixed(settings.precision).slice(2);

  // Apply delimiter to whole number part
  const intString = intPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, settings.delimiter);

  return `${settings.unit}${intString}${settings.separator}${fracPart}`;
}

export function isMobileUserAgent() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent);
}
