import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="list-entry"
export default class extends Controller {
  static targets = ["destroyInput"];

  remove() {
    if (this.hasDestroyInputTarget) {
      this.destroyInputTarget.value = true;
      this.element.classList.add("hidden");
      this.element.removeAttribute("data-list-target");
    } else {
      this.element.remove();
    }
  }
}
