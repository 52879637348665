import { Controller } from "@hotwired/stimulus";
import { buildSpinner, flashNow, getMetaContent } from "./helpers";

// Connects to data-controller="invite-mutual-followers"
export default class extends Controller {
  DEFAULT_ERROR_MESSAGE = "Something went wrong, please try again.";

  static targets = [
    "userInviteButton", // Each user's invite button
    "inviteAllButton", // The button to invite all mutual followers
  ];

  connect() {
    this.initialInviteAllButtonHtml = this.inviteAllButtonTarget.innerHTML;
  }

  emitLoadingState() {
    this.spinner = buildSpinner();
    const button = this.inviteAllButtonTarget;
    const buttonSpan = button.querySelector("span");
    const buttonWidth = button.offsetWidth;
    const buttonHeight = button.offsetHeight;
    button.style.minWidth = `${buttonWidth}px`;
    button.style.minHeight = `${buttonHeight}px`;
    button.classList.add("loading");
    buttonSpan.innerHTML = "";
    buttonSpan.appendChild(this.spinner);
    button.disabled = true;
  }

  emitIdleState() {
    this.spinner.remove();
    const button = this.inviteAllButtonTarget;
    button.classList.remove("loading");
    button.innerHTML = this.initialInviteAllButtonHtml;
    button.style.minWidth = "";
    button.style.minHeight = "";
    button.disabled = false;
  }

  emitError(message = this.DEFAULT_ERROR_MESSAGE) {
    this.emitIdleState();
    flashNow("alert", message);
  }

  async postInvites() {
    const csrfToken = getMetaContent("csrf-token");
    const path = this.inviteAllButtonTarget.dataset.path;

    const response = await fetch(path, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
    });

    if (!response.ok) {
      this.emitError();
      return;
    }

    const data = await response.json().catch(() => {
      this.emitError();
      return;
    });

    return data;
  }

  disableInviteButton(button) {
    const icon = document.createElement("i");
    const span = button.querySelector("span");
    icon.classList.add("fa-solid", "fa-check");
    span.innerText = "Invited";
    button.disabled = true;
    button.innerHTML = "";
    button.appendChild(icon);
    button.appendChild(span);
  }

  disableInviteButtons(userIds) {
    const buttons = this.userInviteButtonTargets;

    buttons.forEach((button) => {
      const { userId } = button.dataset;

      if (userIds.includes(userId)) {
        this.disableInviteButton(button);
      }
    });
  }

  emitSuccess(count) {
    this.emitIdleState();
    const pluralized = count === 1 ? "follower" : "followers";
    flashNow("notice", `Invitation sent to ${count} mutual ${pluralized}.`);
  }

  async inviteAll() {
    this.emitLoadingState();
    const { user_ids } = await this.postInvites();
    const count = user_ids.length;
    this.disableInviteButtons(user_ids);
    this.emitSuccess(count);
    this.inviteAllButtonTarget.disabled = true;
  }
}
