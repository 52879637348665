import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static targets = ["modal"];

  initialize() {
    this.fullscreen = this.data.get("fullscreen") || false;
    this.initializeFullscreen();
    window.addEventListener("modal:close", this.onModalClose);
  }

  initializeFullscreen() {
    if (!this.fullscreen) return;

    this.scrollY = window.scrollY;
    setTimeout(() => {
      document.body.classList.add("modal-open");
    }, 350);
    // slight delay to prevent flickering
    // the 'modal-open' class adds position: fixed to the body on mobile
    // to prevent the page from scrolling while the modal is open
  }

  disconnectFullscreen() {
    if (!this.fullscreen) return;

    document.body.classList.remove("modal-open");
    window.scrollTo(0, this.scrollY);
  }

  handleKeyup(event) {
    if (event.code !== "Escape") return;

    this.closeModal();
  }

  handleClick(event) {
    if (event.target != this.element) return;

    this.closeModal();
  }

  onModalClose = (event) => {
    const turboFrameId = this.element.parentElement.getAttribute("id");
    if (event.detail.modalId !== turboFrameId) return;

    this.closeModal();
  };

  closeModal() {
    this.disconnectFullscreen();
    this.element.classList.add("animate__fadeOut");
    this.element.addEventListener("animationend", () => {
      this.element.parentElement.removeAttribute("src");
      this.modalTarget.parentElement.remove();
    });
  }

  submitEnd(e) {
    if (!e.detail.success) return;

    this.closeModal();
  }

  disconnect() {
    window.removeEventListener("modal:close", this.onModalClose);
  }
}
