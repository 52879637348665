import { Controller } from "@hotwired/stimulus";
import { isDesktop, isMobileUserAgent } from "./helpers";
import { debounce, throttle } from "lodash";

export default class extends Controller {
  static values = {
    browseMenuUrl: String,
    minSearchLength: { type: Number, default: 1 },
    searchDelay: { type: Number, default: 250 },
    searchParam: { type: String, default: "query" },
    searchUrl: String,
  };

  static targets = [
    "browseMenu", // Remote turbo-frame in the sidebar that loads browse menus
    "browseNavHeader", // Mobile header that appears in sub-browse menus (e.g. "Browse by Genre")
    "flash", // Flash message container
    "mainContent", // Main content container
    "sidebar", // Sidebar container
    "sidebarToggle", // Toggle button that opens/closes the sidebar
    "searchInput", // Search input field within the search section
    "searchResults", // Turbo frame that is displayed in place of the main content container when search results are shown
    "searchSection", // Search section that takes up the full screen on mobile, sidebar on desktop
  ];

  initialize() {
    this.verifyWebpSupport();
    document.addEventListener("turbo:before-cache", this.beforeCache.bind(this));

    this.fetchSearchResults = debounce(this.fetchSearchResults, this.searchDelayValue);

    this.setInitialSidebarState();

    if (isMobileUserAgent()) {
      window.addEventListener("orientationchange", this.onOrientationChange);
    } else {
      this.onWindowResize = throttle(this.onWindowResize, 250);
      window.addEventListener("resize", this.onWindowResize);
    }

    this.element.setAttribute("data-main-controller-initialized", true);
  }

  searchResultsTargetConnected(element) {
    element.addEventListener("turbo:frame-load", this.onSearchResultsLoad);
  }

  searchResultsTargetDisconnected(element) {
    element.removeEventListener("turbo:frame-load", this.onSearchResultsLoad);
  }

  setInitialSidebarState() {
    if (isMobileUserAgent() || !isDesktop()) {
      return;
    }

    this.openSidebar();
  }

  beforeCache() {
    this.clearFlash();
    this.clearFrames();
    this.disableBrowseMode();
  }

  // Ensure we don't see flash messages when navigating back
  clearFlash() {
    this.flashTarget.innerHTML = "";
  }

  onOrientationChange = () => {
    if (isDesktop()) {
      this.openSidebar();
    } else {
      this.closeSidebar();
    }
  };

  // Remove the src attribute from all non lazy-loaded remote turbo-frames
  // This prevents the browser from loading the frame's remote content again
  // when the page is restored from cache.
  clearFrames() {
    this.element.querySelectorAll("turbo-frame").forEach((frame) => {
      const lazyLoading = frame.getAttribute("loading") === "lazy";
      if (!frame.hasAttribute("src") || lazyLoading) return;

      frame.removeAttribute("src");
    });

    if (!this.element.parentElement) return;

    this.element.parentElement.removeAttribute("src");
  }

  verifyWebpSupport() {
    this.webpIsSupported().then((supported) => {
      if (supported) {
        document.documentElement.classList.add("webp");
      } else {
        document.documentElement.classList.add("no-webp");
      }
    });
  }

  async webpIsSupported() {
    // If the browser doesn't support createImageBitmap, webp is not supported
    if (!self.createImageBitmap) return false;

    // Base64 representation of a white point webp image
    const webpData = "data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=";

    // Retrieve the image in blob format
    const blob = await fetch(webpData).then((r) => r.blob());

    // If the createImageBitmap method succeeds, return true, otherwise false
    return createImageBitmap(blob).then(
      () => true,
      () => false
    );
  }

  browseNavHeaderTargetConnected() {
    this.searchSectionTarget.classList.add("has-browse-nav-header");
  }

  browseNavHeaderTargetDisconnected() {
    this.searchSectionTarget.classList.remove("has-browse-nav-header");
  }

  onSearchInputFocus() {
    this.loadBrowseMenu();
  }

  onSearchNavClick(event) {
    this.closeSidebar();
    this.loadBrowseMenu();
    this.searchInputTarget.focus();
  }

  loadBrowseMenu() {
    this.browseMenuTarget.src = "";
    this.browseMenuTarget.src = this.browseMenuUrlValue;
    this.enableBrowseMode();
  }

  enableBrowseMode() {
    this.browseMenuTarget.classList.add("active");
    this.searchInputTarget.setAttribute("aria-expanded", "true");
    this.element.classList.add("browse-mode");
    window.addEventListener("click", this.onBrowseClick);
  }

  disableBrowseMode() {
    if (!this.hasBrowseMenuTarget) {
      return;
    }

    this.browseMenuTarget.classList.remove("active");
    this.searchInputTarget.setAttribute("aria-expanded", "false");
    this.element.classList.remove("browse-mode");
    this.browseMenuTarget.innerHTML = "";
    window.removeEventListener("click", this.onBrowseClick);
  }

  onBrowseClick = (event) => {
    const clickedFromSearchSection = this.searchSectionTarget.contains(event.target);
    const searchInputFocused = document.activeElement === this.searchInputTarget;
    const searchResultsVisible = this.element.classList.contains("has-search-results");

    if (!isDesktop() || clickedFromSearchSection || searchInputFocused || searchResultsVisible) {
      return;
    }

    this.disableBrowseMode();
    window.removeEventListener("click", this.onBrowseClick);
  };

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "auto" });
  }

  setSearchLoading() {
    this.searchSectionTarget.classList.add("loading");
  }

  clearSearchLoading() {
    this.searchSectionTarget.classList.remove("loading");
  }

  showSearchResults() {
    this.element.classList.add("has-search-results");
    this.mainContentTarget.setAttribute("aria-hidden", "true");
  }

  removeSearchResults() {
    this.element.classList.remove("has-search-results");
    this.mainContentTarget.setAttribute("aria-hidden", "false");
    this.searchResultsTarget.removeAttribute("src");
    this.searchResultsTarget.innerHTML = "";
  }

  minimumSearchLengthMet() {
    const query = this.searchInputTarget.value.trim();
    return query.length >= this.minSearchLengthValue;
  }

  fetchSearchResults = () => {
    if (!this.minimumSearchLengthMet()) {
      this.removeSearchResults();
      this.clearSearchLoading();
      return;
    }

    const query = this.searchInputTarget.value.trim();
    const url = new URL(this.searchUrlValue);
    url.searchParams.set(this.searchParamValue, query);

    this.setSearchLoading();
    this.searchResultsTarget.setAttribute("src", url);
  };

  onSearchResultsLoad = (event) => {
    if (event.target !== this.searchResultsTarget) return;

    if (!this.minimumSearchLengthMet()) {
      this.removeSearchResults();
      return;
    }

    this.clearSearchLoading();
    this.showSearchResults();
  };

  onSearchChange = (event) => {
    if (!this.minimumSearchLengthMet()) {
      this.removeSearchResults();
      this.clearSearchLoading();
      return;
    }

    this.fetchSearchResults();
  };

  onWindowResize = () => {
    this.onOrientationChange();
  };

  onSidebarOverlayClick = (event) => {
    this.closeSidebar();
  };

  onSidebarToggleClick = (event) => {
    const sidebarHidden = this.sidebarTarget.getAttribute("aria-hidden") === "true";

    this.sidebarTarget.setAttribute("aria-hidden", !sidebarHidden);
    this.sidebarToggleTargets.forEach((target) => {
      target.setAttribute("aria-expanded", sidebarHidden);
    });
  };

  closeSidebar() {
    if (!this.hasSidebarTarget) {
      return;
    }

    this.sidebarTarget.setAttribute("aria-hidden", "true");
    this.sidebarToggleTargets.forEach((target) => {
      target.setAttribute("aria-expanded", "false");
    });
  }

  openSidebar() {
    if (!this.hasSidebarTarget) {
      return;
    }

    this.sidebarTarget.setAttribute("aria-hidden", "false");
    this.sidebarToggleTargets.forEach((target) => {
      target.setAttribute("aria-expanded", "true");
    });
  }

  disconnect() {
    document.removeEventListener("turbo:before-cache", this.beforeCache.bind(this));

    if (isMobileUserAgent()) {
      window.removeEventListener("orientationchange", this.onOrientationChange);
    } else {
      window.removeEventListener("resize", this.onWindowResize);
    }
  }
}
