import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { SCREEN_XS, SCREEN_LG } from "./breakpoints";

// Connects to data-controller="scrollable-media"
export default class extends Controller {
  static targets = ["scrollable", "previousButton", "nextButton", "item"];

  initialize() {
    this.snapAlign = this.data.get("snap-align") || "center";
    this.initializeVisibilityObserver();

    this.itemTargets.forEach((item) => {
      this.visibilityObserver.observe(item);
    });

    this.updateControls = throttle(this.updateControls, 250).bind(this);
    this.scrollableTarget.addEventListener("scroll", this.updateControls);
    window.addEventListener("resize", this.updateControls);
    this.displayControls();
    this.initializeScrollPosition();
    this.initializeMutationObserver();
  }

  // If new items are added to the scrollable container, we need
  // determine if we need to show the scroll controls
  initializeMutationObserver() {
    const config = { childList: true, subtree: true };

    this.mutationObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type !== "childList") {
          return;
        }

        this.updateControls();
      });
    });

    this.mutationObserver.observe(this.scrollableTarget, config);
  }

  itemWidth() {
    return this.itemTargets[0].offsetWidth;
  }

  isFullScreen() {
    return this.element.classList.contains("full-screen");
  }

  // Scroll half an item to the right to keep the first item centered on mobile
  initializeScrollPosition() {
    if (
      !this.hasItemTarget ||
      window.innerWidth > SCREEN_LG ||
      this.snapAlign !== "center"
    ) {
      return;
    }

    const itemWidth = this.itemTargets[0].offsetWidth;
    this.scrollableTarget.scrollLeft = itemWidth / 2;
  }

  displayControls() {
    this.element.style.display = "flex";
    const offsetWidth = this.scrollableTarget.offsetWidth;
    const scrollWidth = this.scrollableTarget.scrollWidth;

    if (scrollWidth > offsetWidth) {
      this.nextButtonTarget.disabled = false;
    }

    this.element.style.display = "";
  }

  initializeVisibilityObserver() {
    const options = {
      root: this.scrollableTarget,
      rootMargin: this.data.get("visibility-root-margin") || "0px",
      threshold: parseFloat(this.data.get("visibility-threshold") || 0.7),
    };

    this.visibilityObserver = new IntersectionObserver(
      this.handleVisibilityIntersection.bind(this),
      options
    );
  }

  handleVisibilityIntersection(entries, observer) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.remove("not-intersecting");
      } else {
        entry.target.classList.add("not-intersecting");
      }
    });
  }

  scrollLeft(event) {
    event.stopPropagation();

    let newPosition;

    if (this.isFullScreen()) {
      newPosition = this.scrollableTarget.scrollLeft - this.itemWidth();
    } else {
      newPosition =
        this.scrollableTarget.scrollLeft - this.scrollableTarget.offsetWidth;
    }

    this.scrollTo(newPosition);
  }

  scrollRight(event) {
    event.stopPropagation();

    let newPosition;

    if (this.isFullScreen()) {
      newPosition = this.scrollableTarget.scrollLeft + this.itemWidth();
    } else {
      newPosition =
        this.scrollableTarget.scrollLeft + this.scrollableTarget.offsetWidth;
    }

    this.scrollTo(newPosition);
  }

  scrollTo(newScrollLeft) {
    this.scrollableTarget.scroll({
      left: newScrollLeft,
      behavior: "smooth",
    });
  }

  updateControls() {
    const scrollPos = this.scrollableTarget.scrollLeft;
    const scrollWidth = this.scrollableTarget.scrollWidth;
    const elementWidth = this.scrollableTarget.offsetWidth;
    const threshold = 50;

    this.previousButtonTarget.disabled = scrollPos < threshold;
    this.nextButtonTarget.disabled =
      scrollPos >= scrollWidth - elementWidth - threshold;
  }

  onItemClick(event) {
    event.stopPropagation();
    const windowWidth = window.innerWidth;

    if (this.isFullScreen() || windowWidth < SCREEN_XS) return;

    window.addEventListener("click", this.closeOnClick.bind(this));
    window.addEventListener("keyup", this.closeOnEsc.bind(this));
    this.element.classList.add("full-screen");

    // Scroll to the clicked item
    const item = event.target.closest(".scrollable-media__item");
    const itemIndex = this.itemTargets.indexOf(item);
    const itemWidth = this.itemWidth();
    const scrollPosition = itemWidth * itemIndex;
    this.scrollableTarget.scrollLeft = scrollPosition;
  }

  closeFullScreen() {
    this.element.classList.remove("full-screen");
  }

  closeOnClick(event) {
    if (!this.isFullScreen()) {
      return;
    }

    this.closeFullScreen();
  }

  closeOnEsc(event) {
    if (event.key !== "Escape") return;

    this.closeFullScreen();
  }

  disconnect() {
    this.visibilityObserver.disconnect();
    this.scrollableTarget.removeEventListener("scroll", this.updateControls);
    window.removeEventListener("click", this.closeOnClick.bind(this));
    window.removeEventListener("keyup", this.closeOnEsc.bind(this));
    window.removeEventListener("resize", this.updateControls);
  }
}
