import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="cached-scroll"
// This controller is used to cache the scroll position of a scrollable element
// When the user navigates back to the page using the back button,
// the scroll position is restored
export default class extends Controller {
  initialize() {
    this.scrollToCachedPosition();
    this.onScroll = throttle(this.onScroll.bind(this), 250);
    this.element.addEventListener("scroll", this.onScroll);
  }

  scrollToCachedPosition() {
    const cachedScroll = this.element.getAttribute("data-cached-scroll");
    if (!cachedScroll) return;

    this.element.scrollTo(0, parseInt(cachedScroll));
  }

  onScroll() {
    this.element.setAttribute("data-cached-scroll", this.element.scrollTop);
  }

  disconnect() {
    this.element.removeEventListener("scroll", this.onScroll);
  }
}
