import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="load-recaptcha-v2"
export default class extends Controller {
  static values = {
    siteKey: String, // The sitekey for the reCAPTCHA v2 widget
    renderTarget: { type: String, default: "recaptchaV2" }, // The target element for the widget
    theme: { type: String, default: "light" }, // The color theme of the widget
    testMode: Boolean, // Whether the Rails environment is in test mode
  };

  connect() {
    this.element.setAttribute("data-recaptcha-v2-loaded", "true");

    if (this.testModeValue) {
      return;
    }

    this.renderRecaptchaV2();
  }

  renderRecaptchaV2() {
    grecaptcha.render(this.renderTargetValue, {
      sitekey: this.siteKeyValue,
      theme: this.themeValue,
    });
  }
}
