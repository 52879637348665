import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="accordion"
export default class extends Controller {
  static targets = ["button", "panel"];

  initialize() {
    this.active = false;
    this.onResize = throttle(this.onResize, 100);
    this.element.setAttribute("data-accordion-initialized", true);
  }

  toggle() {
    this.active = !this.active;

    if (this.active) {
      this.expand();
    } else {
      this.contract();
    }
  }

  expand() {
    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.panelTarget.setAttribute("aria-hidden", "false");
    this.setHeight();
    window.addEventListener("resize", this.onResize);
  }

  contract() {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.panelTarget.setAttribute("aria-hidden", "true");
    this.panelTarget.style.maxHeight = "0px";
    window.removeEventListener("resize", this.onResize);
  }

  setHeight = () => {
    this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px";
  };

  onResize = () => {
    if (!this.active) {
      return;
    }

    this.setHeight();
  };

  disconnect() {
    window.removeEventListener("resize", this.onResize);
  }
}
