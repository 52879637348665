import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="post"
export default class extends Controller {
  static values = {
    expandOnLoad: Boolean,
  };

  static targets = ["discussion", "discussionToggle", "discussionPage"];

  initialize() {
    this.initializeDiscussionObserver();
    this.loadedReplyElementIds = [];

    if (this.expandOnLoadValue) {
      this.toggleDiscussion();
      this.discussionTarget.style.maxHeight = "none";
      this.discussionTarget.overflow = "visible";
    }
  }

  initializeDiscussionObserver() {
    if (!this.hasDiscussionTarget) return;

    this.discussionObserver = new MutationObserver(
      this.discussionObserverCallback.bind(this)
    );
    this.discussionObserver.observe(this.discussionTarget, {
      childList: true,
      subtree: true,
    });
  }

  // Extend discussion if added element has data-from-turbo-stream == true
  discussionObserverCallback(mutationsList, observer) {
    mutationsList.forEach((mutation) => {
      if (mutation.type === "childList") {
        mutation.addedNodes.forEach((node) => {
          if (this.loadedReplyElementIds.includes(node.id)) {
            node.remove();
          }

          if (!node.dataset || !node.dataset.fromTurboStream) return;

          if (!this.element.classList.contains("discussion-expanded")) {
            this.toggleDiscussion();
          }

          this.extendDiscussion();
        });
      }
    });
  }

  discussionPageTargetConnected(turboFrame) {
    turboFrame.addEventListener(
      "turbo:frame-load",
      this.extendDiscussion.bind(this)
    );
    turboFrame.addEventListener(
      "turbo:before-fetch-request",
      this.extendDiscussion.bind(this)
    );
  }

  discussionPageTargetDisconnected(turboFrame) {
    turboFrame.removeEventListener(
      "turbo:frame-load",
      this.extendDiscussion.bind(this)
    );
    turboFrame.removeEventListener(
      "turbo:before-fetch-request",
      this.extendDiscussion.bind(this)
    );
  }

  discussionTargetConnected(turboFrame) {
    turboFrame.addEventListener(
      "turbo:frame-load",
      this.appendReplyElementIds.bind(this)
    );
  }

  discussionTargetDisconnected(turboFrame) {
    turboFrame.removeEventListener(
      "turbo:frame-load",
      this.appendReplyElementIds.bind(this)
    );
  }

  collapseDiscussion() {
    this.discussionTarget.style.maxHeight = "0px";
    this.discussionTarget.style.overflow = "hidden";
  }

  appendReplyElementIds(event) {
    const { target } = event;
    const replyElements = target.querySelectorAll(".post--reply");
    const replyElementIds = Array.from(replyElements).map(
      (replyElement) => replyElement.id
    );
    this.loadedReplyElementIds.push(...replyElementIds);
  }

  extendDiscussion(event = null) {
    if (event && !this.discussionTarget.contains(event.target)) {
      return;
    } else if (event && event.target.dataset.replyTarget) {
      this.discussionTarget.style.maxHeight = "none";
      return;
    }

    const { scrollHeight } = this.discussionTarget;
    const bodyHeight = document.body.clientHeight;
    this.discussionTarget.style.maxHeight = `${bodyHeight}px`;

    setTimeout(() => {
      this.discussionTarget.style.overflow = "visible";
    }, 500);
  }

  toggleDiscussion() {
    if (!this.hasDiscussionTarget) return;

    this.element.classList.toggle("discussion-expanded");
    const ariaExpanded =
      this.discussionToggleTarget.getAttribute("aria-expanded") === "true";
    this.discussionToggleTarget.setAttribute("aria-expanded", !ariaExpanded);
    const ariaHidden =
      this.discussionTarget.getAttribute("aria-hidden") === "true";
    this.discussionTarget.setAttribute("aria-hidden", !ariaHidden);

    if (ariaHidden) {
      this.extendDiscussion();
    } else {
      this.collapseDiscussion();
    }
  }

  disconnect() {
    if (!this.discussionObserver) {
      return;
    }

    this.discussionObserver.disconnect();
  }
}
