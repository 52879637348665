import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="group-invites"
export default class extends Controller {
  static targets = [
    "actions", // footer in modal with send button
    "count", // count displayed in modal
    "inviteContainer", // grid to display invited users
    "invitedUser", // individual invited user in the grid
    "option", // button to invite user in modal
  ];

  connect() {
    this.invitedUserIds = {};
    this.setInitialInvites();
    this.updateUI();
  }

  setInitialInvites() {
    this.invitedUserTargets.forEach((element) => {
      const { userId } = element.dataset;
      this.invitedUserIds[userId] = true;
    });
  }

  get count() {
    return Object.keys(this.invitedUserIds).length;
  }

  countTargetConnected(element) {
    this.updateUI();
  }

  updateUI() {
    this.updateClasses();
    if (!this.hasCountTarget) return;

    this.countTarget.textContent = this.count;
  }

  updateClasses() {
    const hasInvites = this.count > 0;

    this.inviteContainerTarget.classList.toggle("has-invites", hasInvites);
    if (!this.hasActionsTarget) return;

    this.actionsTarget.classList.toggle("active", hasInvites);
  }

  toggleInvite(event) {
    const button = event.currentTarget;
    const { dataset } = button;
    const isSelected = dataset.selected === "true";

    if (isSelected) {
      this.removeInvite(dataset.userId);
    } else {
      this.addInvite(dataset);
    }

    button.setAttribute("data-selected", !isSelected);
  }

  optionTargetConnected(element) {
    const { userId } = element.dataset;

    if (!this.invitedUserIds[userId]) {
      return;
    }

    element.setAttribute("data-selected", true);
  }

  buildInvitedUser(dataset) {
    const { userId, userName, userAvatarUrl } = dataset;

    return `
      <div class="invited-user animate__animated animate__fadeIn" data-group-invites-target="invitedUser" data-user-id="${userId}">
        <div class="avatar-wrapper" data-action="click->group-invites#removeClick" data-user-id="${userId}" title="Remove">
          <img src="${userAvatarUrl}" alt="${userName}">
          <i class="fas fa-times"></i>
        </div>
        <span>${userName}</span>
        <input type="hidden" name="group[invited_user_ids][]" value="${userId}">
      </div>
    `;
  }

  removeClick(event) {
    const { userId } = event.currentTarget.dataset;
    this.removeInvite(userId);
  }

  addInvite(dataset) {
    const { userId } = dataset;
    const invitedUser = this.buildInvitedUser(dataset);

    this.inviteContainerTarget.insertAdjacentHTML("beforeend", invitedUser);
    this.invitedUserIds[userId] = true;
    this.updateUI();
  }

  removeInvite(userId) {
    const invitedUser = this.invitedUserTargets.find(
      (element) => element.dataset.userId === userId
    );

    invitedUser.remove();
    delete this.invitedUserIds[userId];
    this.updateUI();
  }
}
