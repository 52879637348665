import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

// Connects to data-controller="list"
export default class extends Controller {
  static targets = [
    "entriesSection", // Entire section containing list entries and search field
    "listEntries", // Container for list entries
    "listEntry", // Individual list entry
    "searchInput", // Input used to search for titles
    "searchResult", // Individual search result from search field
    "validationInput", // Input used to enable/disable submit based on presence of list entries
  ];

  initialize() {
    this.entries = {
      TVShow: {},
      Movie: {},
    };

    this.sortable = this.initializeSortable();
  }

  initializeSortable() {
    return Sortable.create(this.listEntriesTarget, {
      animation: 150,
      handle: ".list-entry",
      delay: 100,
      delayOnTouchOnly: true,
      draggable: ".list-entry",

      onEnd: (event) => {
        this.syncPositions();
      },
    });
  }

  listEntryTargetConnected(element) {
    this.appendTitle(element.dataset);
    this.syncPositions();

    if (
      this.listEntryTargets.length > 0 &&
      !this.entriesSectionTarget.classList.contains("has-entries")
    ) {
      this.entriesSectionTarget.classList.add("has-entries");
      this.validationInputTarget.removeAttribute("data-form-target");
    }
  }

  listEntryTargetDisconnected(element) {
    this.removeTitle(element.dataset);
    this.syncPositions();

    if (this.listEntryTargets.length === 0) {
      this.entriesSectionTarget.classList.remove("has-entries");
      this.validationInputTarget.setAttribute(
        "data-form-target",
        "requiredInput"
      );
    }
  }

  appendTitle(data) {
    const { titleId, mediaType, sourceId } = data;
    this.entries[mediaType][sourceId] = titleId;
  }

  removeTitle(data) {
    const { mediaType, sourceId } = data;
    delete this.entries[mediaType][sourceId];
  }

  syncPositions() {
    this.listEntryTargets.forEach((element, index) => {
      const positionSpan = element.querySelector(".list-position");
      const positionInput = element.querySelector(".list-position-input");
      const newPosition = index + 1;

      positionSpan.innerText = newPosition;
      positionInput.value = newPosition;
    });
  }

  searchResultTargetConnected(element) {
    const { mediaType, sourceId } = element.dataset;

    if (this.entries[mediaType][sourceId]) {
      element.setAttribute("data-disabled", "true");
    }
  }

  searchFocus() {
    this.searchInputTarget.focus();
  }
}
