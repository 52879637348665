import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="multi-step"
export default class extends Controller {
  static values = {
    activeClass: { type: String, default: "active" },
    endDataAction: { type: String, default: "modal#closeModal" },
  };

  static targets = ["nextButton", "previousButton", "step", "indicator"];

  connect() {
    if (this.stepTargets.length === 0) {
      console.error("No steps found for multi-step controller");
      return;
    }

    this.currentStep = null;
    this.originalDataActionValue = this.nextButtonTarget.dataset.action;
    this.showStep(1);
  }

  showStep(stepNumber) {
    if (this.currentStep === stepNumber) {
      return;
    }

    this.previousButtonTarget.classList.toggle("!hidden", stepNumber === 1);
    this.currentStep = stepNumber;
    this.updateStepClasses();
    this.updateIndicatorClasses();
    this.updateNextButtonAction();
  }

  updateNextButtonAction() {
    const button = this.nextButtonTarget;

    if (this.currentStep === this.stepTargets.length) {
      button.dataset.action = this.endDataActionValue;
    } else {
      button.dataset.action = this.originalDataActionValue;
    }
  }

  updateStepClasses() {
    this.stepTargets.forEach((step) => {
      const active = parseInt(step.dataset.step) === this.currentStep;
      step.classList.toggle(this.activeClassValue, active);
    });
  }

  updateIndicatorClasses() {
    this.indicatorTargets.forEach((indicator) => {
      const active = parseInt(indicator.dataset.step) === this.currentStep;
      indicator.classList.toggle(this.activeClassValue, active);
    });
  }

  onIndicatorClick(event) {
    const indicator = event.currentTarget;
    const step = parseInt(indicator.dataset.step);
    this.showStep(step);
  }

  showNext() {
    this.showStep(this.currentStep + 1);
  }

  showPrevious() {
    this.showStep(this.currentStep - 1);
  }
}
