// Breakpoints for Tailwind CSS, see tailwind.config.js

export const SCREEN_XS = 400;
export const SCREEN_SM = 640;
export const SCREEN_MD = 768;
export const SCREEN_LG = 1024;
export const SCREEN_XL = 1280;
export const SCREEN_2XL = 1536;
export const SCREEN_3XL = 2000;
export const SCREEN_DESKTOP_HEIGHT = 600;
export const SCREEN_DESKTOP_WIDTH = 1024;
