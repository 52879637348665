import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="spoilers"
export default class extends Controller {
  static targets = ["spoiler", "button"];

  reveal() {
    this.spoilerTarget.setAttribute("aria-hidden", "false");
    this.spoilerTarget.classList.remove("hidden");
    this.buttonTarget.classList.add("hidden");
  }
}
