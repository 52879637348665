import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="feed"
export default class extends Controller {
  static targets = ["tab"];

  onTabClick(event) {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });

    const clickedTab = event.currentTarget;
    this.tabTargets.forEach((tab) => {
      const isSelected = tab === clickedTab;
      if (isSelected) {
        return tab.classList.add("active");
      }

      tab.classList.remove("active");
    });
  }
}
