import { Controller } from "@hotwired/stimulus";
import { flashNow, isTouchDevice } from "./helpers";

export default class extends Controller {
  static values = {
    id: String,
  };

  static targets = [
    "observable", // The element that is observed for changes, see app/views/reactions/_reaction_bar.html.erb
    "upvote", // The upvote button, see app/views/reactions/_upvote.html.erb
    "upvotersPreview", // UpvotersPreviewComponent, see app/components/upvoters_preview_component.rb
  ];

  longPressDuration = 500; // Duration the user needs to touch the upvote button to show the upvoters preview
  openPreviewDelay = 500; // The delay before the upvoters preview is shown
  hidePreviewDelay = 400; // The delay that allows the user to move the mouse from the upvote button to the upvoters preview

  connect() {
    this.isLongPress = false;
    this.longPressTimer = null;
    this.upvotePreviewVisible = false;
    this.touchStarted = false;
    this.lastActionWasMouseClick = false;
    this.element.setAttribute("data-reaction-bar-connected", true);
  }

  observableTargetConnected(element) {
    this.syncObserver = new MutationObserver(this.syncReactionBars);
    this.syncObserver.observe(element, {
      childList: true,
      subtree: true,
    });
  }

  observableTargetDisconnected(element) {
    this.syncObserver.disconnect();
  }

  syncReactionBars = () => {
    const sourceHTML = this.element.innerHTML;
    const reactionBars = document.querySelectorAll(
      `[data-reaction-bar-id-value="${this.idValue}"]`
    );

    reactionBars.forEach((reactionBar) => {
      if (reactionBar.innerHTML === sourceHTML) {
        return;
      }

      reactionBar.innerHTML = sourceHTML;
    });
  };

  upvoteTargetConnected(element) {
    this.handleUpvoteListeners(element, "add");
  }

  upvoteTargetDisconnected(element) {
    this.handleUpvoteListeners(element, "remove");
  }

  upvotersPreviewTargetConnected(element) {
    this.handlePreviewListeners(element, "add");

    if (!this.hasUpvoteTarget) {
      return;
    }

    this.addAriaAttributes();
  }

  upvotersPreviewTargetDisconnected(element) {
    this.handlePreviewListeners(element, "remove");
  }

  handleUpvoteListeners(element, action) {
    const eventAction = this.determineEventAction(action);

    element[eventAction]("focus", this.onUpvoteFocus);
    element[eventAction]("blur", this.focusUpvotersPreview);

    if (isTouchDevice()) {
      element[eventAction]("touchstart", this.onUpvoteTouchStart);
      element[eventAction]("touchmove", this.onUpvoteTouchMove);
      element[eventAction]("touchend", this.onUpvoteTouchEnd);
    } else {
      element[eventAction]("mouseenter", this.onUpvoteMouseEnter);
      element[eventAction]("mouseleave", this.onUpvoteMouseLeave);
      element[eventAction]("click", this.onUpvoteClick);
    }
  }

  handlePreviewListeners(element, action) {
    const eventAction = this.determineEventAction(action);

    element[eventAction]("blur", this.onUpvotePreviewBlur);
    if (isTouchDevice()) return;

    element[eventAction]("mouseenter", this.onUpvotersPreviewMouseEnter);
    element[eventAction]("mouseleave", this.onUpvotersPreviewMouseLeave);
  }

  addAriaAttributes() {
    this.upvoteTarget.setAttribute("aria-expanded", false);
    this.upvoteTarget.setAttribute("aria-haspopup", true);
    this.upvoteTarget.setAttribute(
      "aria-controls",
      this.upvotersPreviewTarget.id
    );
    this.upvotersPreviewTarget.setAttribute("aria-hidden", true);
  }

  onUpvoteMouseEnter = (event) => {
    this.upvoteMouseEntered = true;

    if (
      event.relatedTarget.dataset.reactionBarTarget === "upvote" ||
      this.upvotePreviewVisible
    ) {
      return;
    }

    this.handleMouseEnterTimeout(event);
  };

  onUpvoteClick = (event) => {
    this.lastActionWasMouseClick = true;

    if (!this.upvoteMouseEnterTimeout) {
      return;
    }

    clearTimeout(this.upvoteMouseEnterTimeout);
  };

  onUpvoteMouseLeave = (event) => {
    this.upvoteMouseEntered = false;
    this.handleMouseLeave(event);
  };

  onUpvotersPreviewMouseLeave = (event) => {
    this.handleMouseLeave(event);
  };

  onUpvotersPreviewMouseEnter = (event) => {
    clearTimeout(this.upvoteMouseLeaveTimeout);
  };

  handleMouseEnterTimeout(event) {
    if (this.upvoteMouseEnterTimeout) {
      clearTimeout(this.upvoteMouseEnterTimeout);
    }

    if (this.upvoteMouseLeaveTimeout) {
      clearTimeout(this.upvoteMouseLeaveTimeout);
    }

    this.upvoteMouseEnterTimeout = setTimeout(
      this.showUpvotersPreview,
      this.openPreviewDelay
    );
  }

  handleMouseLeave(event) {
    if (this.upvoteMouseLeaveTimeout) {
      clearTimeout(this.upvoteMouseLeaveTimeout);
    }

    if (this.upvoteMouseEnterTimeout) {
      clearTimeout(this.upvoteMouseEnterTimeout);
    }

    this.upvoteMouseLeaveTimeout = setTimeout(() => {
      if (this.isHoveringUpvotesOrUpvotesPreview(event)) {
        return;
      }

      this.hideUpvotersPreview();
    }, this.hidePreviewDelay);
  }

  onUpvoteTouchStart = (event) => {
    this.touchStarted = true;
    this.isLongPress = false;

    this.longPressTimer = setTimeout(() => {
      this.isLongPress = true;
      this.showUpvotersPreview();
    }, this.longPressDuration);
  };

  onUpvoteTouchMove = () => {
    clearTimeout(this.longPressTimer);
  };

  onUpvoteTouchEnd = (event) => {
    clearTimeout(this.longPressTimer);
    if (this.isLongPress) {
      event.preventDefault();
    }

    setTimeout(() => {
      this.touchStarted = false;
    }, 50); // Small delay to let the button focus
  };

  onUpvotePreviewBlur = (event) => {
    if (!event.relatedTarget) return;
    this.hideUpvotersPreview();
  };

  showUpvotersPreview = () => {
    if (this.hidePreviewTimeout) {
      clearTimeout(this.hidePreviewTimeout);
    }

    this.element.classList.add("show-upvoters-preview");
    this.upvoteTarget.setAttribute("aria-expanded", true);
    this.upvotersPreviewTarget.setAttribute("aria-hidden", false);
    window.addEventListener("click", this.onWindowClick);
    this.upvotePreviewVisible = true;
  };

  hideUpvotersPreview = () => {
    this.element.classList.remove("show-upvoters-preview");
    this.upvoteTarget.setAttribute("aria-expanded", false);
    this.upvotersPreviewTarget.setAttribute("aria-hidden", true);
    window.removeEventListener("click", this.onWindowClick);
    this.upvotePreviewVisible = false;
  };

  focusUpvotersPreview = () => {
    this.upvotersPreviewTarget.focus();
  };

  onWindowClick = (event) => {
    if (this.clickedOnUpvoteOrUpvotersPreview(event)) {
      return;
    }

    this.hideUpvotersPreview();
  };

  onUpvoteFocus = (event) => {
    if (this.lastActionWasMouseClick) {
      this.lastActionWasMouseClick = false;
      return;
    }

    if (this.touchStarted) {
      this.touchStarted = false;
      return;
    }

    if (this.upvoteMouseEntered) {
      return;
    }

    this.showUpvotersPreview();
  };

  isHoveringUpvotesOrUpvotesPreview(event) {
    return (
      event.relatedTarget === this.upvoteTarget ||
      event.relatedTarget === this.upvotersPreviewTarget
    );
  }

  clickedOnUpvoteOrUpvotersPreview(event) {
    return (
      (this.hasUpvoteTarget && this.upvoteTarget.contains(event.target)) ||
      (this.hasUpvoteTarget && this.upvoteTarget === event.target) ||
      this.upvotersPreviewTarget.contains(event.target) ||
      this.upvotersPreviewTarget === event.target
    );
  }

  determineEventAction(action) {
    return action === "add" ? "addEventListener" : "removeEventListener";
  }

  flashSignInRequired(e) {
    const requestedAction = e.currentTarget.dataset.requestedAction;
    const suffix = requestedAction ? requestedAction : "perform this action";

    flashNow("alert", `Sign in or create an account to ${suffix}.`, {
      clearMessages: true,
    });
  }
}
