import { Controller } from "@hotwired/stimulus";
import { getMetaContent, buildFlash, isDesktop } from "./helpers";
import { throttle } from "lodash";
import Tribute from "tributejs";

// Connects to data-controller="mentions"
export default class extends Controller {
  static targets = ["textarea"];

  initialize() {
    if (!this.hasTextareaTarget) return;

    this.dropdownOnDesktop = this.data.get("dropdownOnDesktop") === "true";
    this.dropdownOnMobile = this.data.get("dropdownOnMobile") === "true";
    this.tribute = this.initializeTribute();
    this.tribute.attach(this.textareaTarget);
    this.element.setAttribute("data-initialized", true);

    if (!this.dropdownOnDesktop) return;

    this.onResize = throttle(this.onResize.bind(this), 250);
    window.addEventListener("resize", this.onResize);
  }

  initializeTribute() {
    const dropdown = this.dropdownOnDesktop || this.dropdownOnMobile;
    let containerClass;
    let positionMenu;
    let menuContainer;

    if (dropdown && isDesktop()) {
      containerClass = "mentions mentions--desktop-dropdown";
      positionMenu = true;
    } else if (this.dropdownOnMobile && !isDesktop()) {
      containerClass = "mentions mentions--mobile-dropdown";
      positionMenu = true;
    } else {
      containerClass = "mentions";
      positionMenu = false;
      menuContainer = this.element;
    }

    return new Tribute({
      menuContainer,
      containerClass,
      positionMenu,
      itemClass: "mentions__item",
      selectClass: "mentions__item--selected",
      trigger: "@",
      fillAttr: "username",
      noMatchTemplate: () => this.renderNoMatch(),
      lookup: (user, mentionText) => user.username + user.name,
      values: async (text, cb) => {
        this.fetchUsers(text, cb);
      },
      menuItemTemplate: (item) => this.renderUser(item),
    });
  }

  // Reinitialize Tribute when the screen size changes between mobile and desktop
  onResize() {
    const screenSize = isDesktop() ? "desktop" : "mobile";

    if (screenSize === this.previousScreenSize) {
      return;
    }

    this.tribute.detach(this.textareaTarget);
    this.tribute = this.initializeTribute();
    this.tribute.attach(this.textareaTarget);
    this.previousScreenSize = screenSize;
  }

  async fetchUsers(text, callback) {
    const endpoint = this.data.get("endpoint");
    const endpointQuery = endpoint + "?query=" + text;

    const response = await fetch(endpointQuery, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
    });

    if (!response.ok) {
      buildFlash("alert", "Something went wrong, please try again.");
      return;
    }

    const data = await response.json();

    callback(data);
  }

  buildMentionAvatar(avatar_url) {
    const wrapper = document.createElement("div");
    wrapper.classList.add("mentions__item__avatar");

    const img = document.createElement("img");
    img.src = avatar_url || this.data.get("default-avatar");

    wrapper.appendChild(img);

    return wrapper;
  }

  buildMentionUsername(username) {
    const span = document.createElement("span");
    span.classList.add("mentions__item__username");
    span.innerText = username;

    return span;
  }

  renderUser(item) {
    const { avatar_url, username } = item.original;
    const container = document.createElement("div");
    const avatarEl = this.buildMentionAvatar(avatar_url);
    const usernameEl = this.buildMentionUsername(username);

    container.appendChild(avatarEl);
    container.appendChild(usernameEl);

    return container.innerHTML;
  }

  // Hide the "No matches found" message, can customize this to show a custom message
  renderNoMatch() {
    return ``;
  }

  disconnect() {
    if (!this.tribute) return;

    this.tribute.detach(this.textareaTarget);
    this.element.removeAttribute("data-initialized");
    if (!this.dropdownOnDesktop) return;

    window.removeEventListener("resize", this.onResize);
  }
}
