// Stripe.js appearance options
// See https://stripe.com/docs/elements/appearance-api

export const APPEARANCE_OPTIONS = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css?family=Roboto%20Mono:400,500,600",
    },
  ],
  appearance: {
    theme: "flat",
    variables: {
      borderRadius: "4px",
      colorDanger: "#ff4f4f",
      colorText: "#fff",
      colorPrimary: "#00e3ff",
      colorBackground: "#1e1d24",
      fontFamily: "Roboto Mono",
      fontSizeBase: "14px",
    },
    rules: {
      ".Label": {
        fontWeight: "400",
        color: "#ffffff",
        marginBottom: "8px",
      },
      ".Input": {
        paddingTop: "12px",
        paddingBottom: "12px",
      },
    },
  },
};
