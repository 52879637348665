import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="user-settings"
export default class extends Controller {
  static values = {
    settingsRootPath: { type: String, default: "/settings" },
  };

  connect() {
    this.element.addEventListener(
      "turbo:frame-render",
      this.onTurboFrameRender
    );

    if (this.isSettingsRootPath()) {
      return;
    }

    this.hideNav();
  }

  isSettingsRootPath() {
    return window.location.pathname === this.settingsRootPathValue;
  }

  onTurboFrameRender = (event) => {
    if (event.target !== this.element) {
      return;
    }

    this.hideNav();
  };

  showNav() {
    this.element.classList.remove("closed-nav-mobile");
  }

  hideNav() {
    this.element.classList.add("closed-nav-mobile");
  }

  navigateBack() {
    window.history.back();
  }

  onBackClick() {
    if (this.isSettingsRootPath()) {
      this.showNav();
    } else {
      this.navigateBack();
    }
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:frame-render",
      this.onTurboFrameRender
    );
  }
}
