import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="tab-navigation"
export default class extends Controller {
  static targets = ["tab", "scrollable"];

  connect() {
    if (!this.hasTabTarget) return;

    const initialTab = this.findInitialTab();

    if (!initialTab) return;

    this.selectTab({ currentTarget: initialTab });
  }

  findInitialTab() {
    const selectedDataTab = this.data.get("selected");

    if (!selectedDataTab) {
      return this.tabTargets[0];
    }

    return this.tabTargets.find(
      (tab) => tab.getAttribute("aria-controls") === selectedDataTab
    );
  }

  resetScroll() {
    if (!this.hasScrollableTarget) return;

    this.scrollableTarget.scrollTo(0, 0);
  }

  selectTab(event) {
    const selectedTab = event.currentTarget;
    const tabPanelId = selectedTab.getAttribute("aria-controls");
    this.resetScroll();

    this.tabTargets.forEach((tab) => {
      const isSelected = tab === selectedTab;
      tab.setAttribute("aria-selected", isSelected);
      tab.disabled = isSelected;
    });

    this.element.setAttribute("data-tab-nav-selected", tabPanelId);
  }
}
