import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scroll-to"
export default class extends Controller {
  static values = {
    delay: { type: Number, default: 0 },
  };

  connect() {
    if (this.delayValue > 0) {
      this.timeout = setTimeout(this.focusSelf, this.delayValue);
    } else {
      this.focusSelf();
    }
  }

  focusSelf = () => {
    const element = this.element;
    const rect = element.getBoundingClientRect();
    const padding = 180;
    const topEdge = window.pageYOffset;
    const elementTop = rect.top + window.pageYOffset - padding;
    const scrollAmt = elementTop - topEdge;

    if (scrollAmt !== 0) {
      window.scrollBy({ top: scrollAmt, behavior: "smooth" });
    }
  };

  disconnect() {
    clearTimeout(this.timeout);
  }
}
