import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { flashNow } from "./helpers";

// Connects to data-controller="group-search"
export default class extends Controller {
  static targets = ["form", "frame", "input", "heading"];

  connect() {
    this.search = debounce(this.search, 250);

    this.inputTarget.addEventListener("input", this.search);

    if (!this.frameTarget.src) {
      this.initialHTML = this.frameTarget.innerHTML;
      this.frameTarget.addEventListener(
        "turbo:before-fetch-response",
        this.onResponse
      );
    } else {
      this.frameTarget.addEventListener("turbo:frame-load", this.onInitialLoad);
    }
  }

  onInitialLoad = (event) => {
    this.frameTarget.removeEventListener(
      "turbo:frame-load",
      this.onInitialLoad
    );
    this.frameTarget.addEventListener(
      "turbo:before-fetch-response",
      this.onResponse
    );
    this.initialHTML = this.frameTarget.innerHTML;
  };

  search = (event) => {
    const query = event.target.value.trim();

    if (query.length === 0) {
      this.frameTarget.innerHTML = this.initialHTML;
      this.headingTarget.innerHTML = "<h1>Your Groups</h1>";
      return;
    }

    if (query.length < 2) return;

    const url = new URL(this.formTarget.action);
    url.searchParams.set("query", query);
    this.headingTarget.innerHTML = "";
    this.frameTarget.setAttribute("src", url);
  };

  onResponse = (event) => {
    const response = event.detail.fetchResponse.response;
    if (response.ok) return;

    this.frameTarget.innerHTML = "";

    if (response.status === 404) {
      this.headingTarget.innerHTML = "<p>No results found.</p>";
      return;
    }

    flashNow("alert", "Something went wrong.");
  };

  disconnect() {
    this.inputTarget.removeEventListener("input", this.search);
    this.frameTarget.removeEventListener(
      "turbo:before-fetch-response",
      this.onResponse
    );
  }
}
