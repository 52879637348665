import { Controller } from "@hotwired/stimulus";
import { buildSpinner, flashNow, getMetaContent } from "./helpers";

// Connects to data-controller="plaid-link"
export default class extends Controller {
  PLAID_CDN_URL = "https://cdn.plaid.com/link/v2/stable/link-initialize.js";

  static values = {
    createTokenPath: String,
    successRedirectUrl: String,
    successRedirectDelay: { type: Number, default: 3000 },
    verifySessionPath: String,
  };

  static targets = ["button", "buttonText"];

  connect() {
    this.initialBtnText = this.buttonTextTarget.innerText;
    this.initialBtnTextWidth = this.buttonTextTarget.offsetWidth;
    this.buttonTextTarget.style.minWidth = `${this.initialBtnTextWidth}px`;
    this.plaidLink = null;

    this.showLoadingIndicator("Initializing...");
    this.insertPlaidScript();
  }

  showLoadingIndicator(text = "Connecting...") {
    const spinner = buildSpinner();
    this.buttonTarget.disabled = true;
    this.buttonTextTarget.textContent = text;
    this.buttonTextTarget.prepend(spinner);
  }

  stopLoadingIndicator() {
    this.buttonTextTarget.textContent = this.initialBtnText;
    this.buttonTarget.disabled = false;
  }

  insertPlaidScript() {
    const script = document.createElement("script");
    script.src = this.PLAID_CDN_URL;
    script.async = true;
    script.defer = true;
    script.onerror = this.onPlaidScriptError;
    script.onload = this.onPlaidScriptLoaded;
    document.head.appendChild(script);
  }

  onPlaidScriptError = (event) => {
    console.error("Failed to load Plaid from CDN", event);
    flashNow("alert", "Failed to load verification service. Please try again.");
    this.buttonTextTarget.textContent = this.initialBtnText;
  };

  onPlaidScriptLoaded = () => {
    this.element.setAttribute("data-plaid-cdn-loaded", true);
    this.stopLoadingIndicator();
  };

  async sendRequest(endpoint, body = {}) {
    try {
      const response = await this.performRequest(endpoint, body);

      if (!response.ok) {
        await this.handleErrorResponse(response);
        return null;
      }

      return await response.json();
    } catch (error) {
      this.handleRequestError(error);
      return null;
    }
  }

  async performRequest(endpoint, body) {
    return fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
      body: JSON.stringify(body),
    });
  }

  async handleErrorResponse(response) {
    const json = await response.json();
    const errorMessage =
      json.error ||
      `An error occurred: ${response.status} ${response.statusText}`;
    flashNow("alert", errorMessage);
    this.stopLoadingIndicator();
  }

  handleRequestError(error) {
    console.error("An error occurred while sending the request:", error);
    flashNow("alert", "Something went wrong, please try again.");
    this.stopLoadingIndicator();
  }

  // Create a link token by sending a POST request to /settings/verification
  async createLinkToken() {
    this.showLoadingIndicator("Connecting...");
    const endpoint = this.createTokenPathValue;
    const { link_token } = await this.sendRequest(endpoint);
    return link_token;
  }

  async openLink() {
    const linkToken = await this.createLinkToken();

    if (!linkToken) {
      return;
    }

    this.plaidLink = this.initializePlaidLink(linkToken);
    this.plaidLink.open();
  }

  // Create an instance of Plaid Link using the link token
  initializePlaidLink(linkToken) {
    return Plaid.create({
      token: linkToken,
      onLoad: this.onPlaidLinkLoaded,
      onSuccess: this.onPlaidLinkSuccess,
      onExit: this.onPlaidLinkExit,
    });
  }

  onPlaidLinkLoaded = () => {
    this.buttonTextTarget.textContent = "Connected";
    this.element.setAttribute("data-plaid-link-loaded", true);
  };

  onPlaidLinkSuccess = async (publicToken, metadata) => {
    const { link_session_id } = metadata;
    const verified = await this.verifySession(link_session_id);

    if (!verified) {
      return;
    }

    this.removePlaidLink();
    this.showRedirectMessage();
    this.redirectToSuccessUrl();
    flashNow("notice", "Your account has been verified!");
  };

  async verifySession(linkSessionId) {
    this.showLoadingIndicator("Verifying...");
    const endpoint = this.verifySessionPathValue;
    const body = { link_session_id: linkSessionId };
    return this.sendRequest(endpoint, body);
  }

  removePlaidLink() {
    if (!this.plaidLink) {
      return;
    }

    this.plaidLink.destroy();
    this.element.removeAttribute("data-plaid-link-loaded");
    this.plaidLink = null;
  }

  showRedirectMessage() {
    const p = document.createElement("p");
    p.textContent = "Redirecting you back to your feed...";
    this.element.innerHTML = "";
    this.element.appendChild(p);
  }

  redirectToSuccessUrl() {
    setTimeout(() => {
      window.location.href = this.successRedirectUrlValue;
    }, this.successRedirectDelayValue);
  }

  onPlaidLinkExit = (error, metadata) => {
    flashNow("alert", "Verification cancelled.");
    this.stopLoadingIndicator();
  };

  removePlaidScript() {
    const script = document.querySelector(
      `script[src="${this.PLAID_CDN_URL}"]`
    );

    if (!script) {
      return;
    }

    script.remove();
  }

  disconnect() {
    this.element.removeAttribute("data-plaid-cdn-loaded");
    this.removePlaidScript();
    this.removePlaidLink();
  }
}
