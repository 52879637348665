import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="class-toggle"
export default class extends Controller {
  static values = { className: String };

  toggle(event) {
    if (!this.hasClassNameValue) {
      throw new Error("Missing value for classNameValue");
    }

    this.element.classList.toggle(this.classNameValue);
  }
}
