import { Controller } from "@hotwired/stimulus";
import { fetchWithCsrf } from "./helpers";

// Fetches the user's calling code and sets the value of the controlled input
// Connects to data-controller="calling-code"
export default class extends Controller {
  static values = {
    endpoint: String, // The URL to fetch the user's calling code
  };

  connect() {
    if (this.element.value !== "") {
      return;
    }

    this.element.placeholder = "Loading...";
    this.element.disabled = true;
    this.setCallingCodeValue();
  }

  setCallingCodeValue() {
    fetchWithCsrf(this.endpointValue)
      .then((response) => response.json())
      .then((data) => {
        this.element.value = data.calling_code;
        this.element.disabled = false;
        this.element.placeholder = "";
      })
      .catch(() => {
        this.element.disabled = false;
        this.element.value = "+1";
      });
  }
}
