import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="turbo-unwrap"
export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:frame-load", this.onFrameLoad);
  }

  disconnect() {
    this.element.removeEventListener("turbo:frame-load", this.onFrameLoad);
  }

  onFrameLoad = (event) => {
    console.log("turbo:frame-load event", event);

    const turboFrame = event.target;
    turboFrame.replaceWith(...turboFrame.childNodes);
  };
}
