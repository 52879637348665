import { Controller } from "@hotwired/stimulus";
import Cropper from "cropperjs";
import { DirectUpload } from "@rails/activestorage";
import { flashNow, bytesToSize } from "./helpers";
import { throttle } from "lodash";

// Connects to data-controller="cropper"
export default class extends Controller {
  static targets = [
    "destination",
    "source",
    "loaderOverlay",
    "previewOriginal",
    "previewCropped",
  ];

  connect() {
    this.maxFileSize = parseInt(this.data.get("maxFileSize"));
    this.sourceChanged = this.sourceChanged.bind(this);
    this.handleSubmitEnd = this.handleSubmitEnd.bind(this);

    this.onCrop = throttle(this.onCrop, 50);
    this.sourceTarget.addEventListener("change", this.sourceChanged);
    this.element.addEventListener("turbo:submit-end", this.handleSubmitEnd);
  }

  enableLoader() {
    this.loaderOverlayTarget.classList.add("active");
  }

  disableLoader() {
    this.loaderOverlayTarget.classList.remove("active");
  }

  handleSubmitEnd(event) {
    this.disableLoader();
  }

  currentFile() {
    return this.sourceTarget.files[0];
  }

  exceedsMaxSize() {
    return this.currentFile() && this.currentFile().size > this.maxFileSize;
  }

  sourceChanged() {
    if (this.exceedsMaxSize()) {
      this.showFileSizeAlert();
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      this.handleImageLoad(event.target.result);
    };
    reader.readAsDataURL(this.currentFile());
  }

  showFileSizeAlert() {
    const message = `File size must be less than ${bytesToSize(
      this.maxFileSize
    )}`;
    flashNow("alert", message);
    this.sourceTarget.value = "";
  }

  handleImageLoad(imageDataUrl) {
    const img = new Image();
    img.onload = () => {
      const resizedImageDataUrl = this.getResizedImageDataUrl(img);
      this.previewOriginalTarget.src = resizedImageDataUrl;

      if (this.cropper) {
        this.cropper.destroy();
      }
      this.loadCropper(this.previewOriginalTarget);
    };
    img.src = imageDataUrl;
  }

  getResizedImageDataUrl(img) {
    const maxWidth = 800;
    const maxHeight = 800;

    let width = img.width;
    let height = img.height;

    if (img.width > maxWidth) {
      const ratio = maxWidth / img.width;
      width = maxWidth;
      height = img.height * ratio;
    }

    if (height > maxHeight) {
      const ratio = maxHeight / height;
      height = maxHeight;
      width = width * ratio;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);

    return canvas.toDataURL();
  }

  loadCropper(imageData) {
    this.cropper = new Cropper(imageData, {
      aspectRatio: 1,
      viewMode: 1,
      autoCropArea: 1,
      checkCrossOrigin: false,
      crop: this.onCrop,
    });
    this.element.classList.add("cropper-active");
  }

  onCrop = (event) => {
    let canvas = this.cropper.getCroppedCanvas();
    this.previewCroppedTarget.src = canvas.toDataURL();
  };

  cropAndUpload(event) {
    event.preventDefault();
    this.enableLoader();

    const canvas = this.cropper.getCroppedCanvas({
      width: 500,
      height: 500,
    });

    canvas.toBlob((blob) => {
      const originalFile = this.currentFile();
      const file = new File([blob], originalFile.name, {
        type: originalFile.type,
      });

      const directUploadUrl = this.sourceTarget.dataset.directUploadUrl;
      const upload = new DirectUpload(file, directUploadUrl);
      upload.create((error, blob) => {
        if (error) {
          console.error(error);
          flashNow("alert", "There was an error uploading your image.");
          this.disableLoader();
        } else {
          this.destinationTarget.value = blob.signed_id;
          this.element.requestSubmit();
        }
      });
    });
  }

  delete() {
    this.element.method = "DELETE";
    this.enableLoader();
    this.element.requestSubmit();
  }

  disconnect() {
    this.sourceTarget.removeEventListener("change", this.sourceChanged);
    this.element.removeEventListener("turbo:submit-end", this.handleSubmitEnd);

    if (this.cropper) {
      this.cropper.destroy();
    }
  }
}
