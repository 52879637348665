import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="expandable-text"
export default class extends Controller {
  static values = {
    maxLines: { type: Number, default: 6 },
  };

  static targets = ["body", "button"];

  connect() {
    this.updateTextClamping();
    this.onResize = throttle(this.onResize.bind(this), 100);
    window.addEventListener("resize", this.onResize);
    this.element.setAttribute("data-initialized", true);
  }

  onResize = () => {
    this.updateTextClamping();
  };

  clamp() {
    this.bodyTarget.style.webkitLineClamp = this.maxLinesValue;
    this.bodyTarget.setAttribute("data-clamped", true);
  }

  unclamp() {
    this.bodyTarget.style.webkitLineClamp = "unset";
    this.bodyTarget.setAttribute("data-clamped", false);
  }

  updateTextClamping() {
    this.clamp();

    const textIsTruncated =
      this.bodyTarget.scrollHeight > this.bodyTarget.clientHeight;

    if (textIsTruncated) {
      this.buttonTarget.classList.remove("hidden");
    } else {
      this.buttonTarget.classList.add("hidden");
      this.unclamp();
    }
  }

  expand() {
    this.unclamp();
    this.buttonTarget.classList.add("hidden");
    window.removeEventListener("resize", this.onResize);
  }

  disconnect() {
    window.removeEventListener("resize", this.onResize);
  }
}
