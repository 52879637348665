import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="youtube"
export default class extends Controller {
  static targets = ["video"];

  initialize() {
    this.players = new Array();

    if (typeof YT === "undefined") {
      this.initializeYouTubeAPI();
    } else {
      this.youTubeReady();
    }
  }

  initializeYouTubeAPI() {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  youTubeReady() {
    this.videoTargets.forEach((video) => {
      const elementId = video.id;
      const playBtn = video.parentElement.querySelector(".play-btn");
      const videoId = video.dataset.videoId;
      this.createPlayer(elementId, playBtn, videoId);
    });
  }

  createPlayer(elementId, playBtn, videoId) {
    new YT.Player(elementId, {
      height: "100%",
      width: "100%",
      videoId,
      playerVars: {
        playsinline: 1,
        modestbranding: 1,
      },
      events: {
        onReady: (event) => this.onPlayerReady(event, playBtn),
        onStateChange: (event) => this.onPlayerStateChange(event, playBtn),
      },
    });
  }

  onPlayerReady(event, playBtn) {
    const player = event.target;
    this.players.push(player);
    playBtn.addEventListener("click", () => this.playVideo(player, playBtn));
    playBtn.classList.remove("inactive");
  }

  playVideo(player, playBtn) {
    const playerElement = player.getIframe();
    playerElement.classList.add("active");
    playBtn.classList.add("inactive");
    player.playVideo();
  }

  onPlayerStateChange(event) {
    if (event.data !== YT.PlayerState.PLAYING) return;

    const currentPlayer = event.target;
    const otherPlayers = this.players.filter(
      (player) => player.id !== currentPlayer.id
    );
    otherPlayers.forEach((player) => player.pauseVideo());
  }
}
