import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="landing-scroll"
export default class extends Controller {
  static values = {
    mobileDevice: { type: Boolean, default: false },
  };

  static targets = ["header"];

  connect() {
    if (!this.mobileDeviceValue) return;

    this.setHeaderHeight();
    window.addEventListener("orientationchange", this.setHeaderHeight);
  }

  disconnect() {
    if (!this.mobileDeviceValue) return;

    window.removeEventListener("orientationchange", this.setHeaderHeight);
  }

  setHeaderHeight = () => {
    const vh = window.innerHeight * 0.95;
    this.headerTarget.style.minHeight = `${vh}px`;
    this.headerTarget.style.maxHeight = `${vh}px`;
  };

  scrollDown() {
    const headerHeight = this.headerTarget.offsetHeight;
    const currentPosition =
      document.documentElement.scrollTop || document.body.scrollTop;
    const distanceToScroll = headerHeight - currentPosition;

    if (currentPosition > headerHeight) return;

    window.scrollBy({
      top: distanceToScroll,
      left: 0,
      behavior: "smooth",
    });
  }
}
