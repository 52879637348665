import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="turbo-cancel"
export default class extends Controller {
  connect() {
    this.beforeFetch = this.beforeFetch.bind(this);
    this.onFrameLoad = this.onFrameLoad.bind(this);

    this.element.addEventListener(
      "turbo:before-fetch-request",
      this.beforeFetch
    );
    this.element.addEventListener("turbo:frame-load", this.onFrameLoad);
  }

  beforeFetch(event) {
    const requestedUrl = event.detail.url.href;
    const currentFrameUrl = this.element.src;
    const hasCancelBtn = this.element.querySelector(".btn-turbo-cancel");

    if (hasCancelBtn && requestedUrl === currentFrameUrl) {
      event.preventDefault();
      this.cancel();
      return;
    }

    this.restoreHtml = this.element.innerHTML;
  }

  onFrameLoad() {
    const cancelButton = document.createElement("button");
    cancelButton.setAttribute("data-action", "turbo-cancel#cancel");
    cancelButton.setAttribute("data-from-turbo-stream", true);
    cancelButton.classList.add("btn-turbo-cancel");
    cancelButton.innerText = "Cancel";
    cancelButton.type = "button";
    this.element.appendChild(cancelButton);
  }

  cancel() {
    this.element.innerHTML = this.restoreHtml;
    this.element.removeAttribute("src");
  }

  disconnect() {
    this.element.removeEventListener(
      "turbo:before-fetch-request",
      this.beforeFetch
    );
    this.element.removeEventListener("turbo:frame-load", this.onFrameLoad);
  }
}
