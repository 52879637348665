import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="collapsible-comment-field"
export default class extends Controller {
  static targets = ["toggleButton", "form", "textarea", "imagePreview"];

  connect() {
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener("click", this.onWindowClick);
    this.formTarget.addEventListener("turbo:submit-start", this.onFormSubmit);
    this.formTarget.addEventListener("turbo:submit-end", this.onFormSubmitEnd);
    this.formTarget.addEventListener(
      "direct-upload:fileAdded",
      this.onFileAdded
    );
    this.formTarget.addEventListener(
      "direct-upload:fileRemoved",
      this.onFileRemoved
    );
    this.textareaTarget.addEventListener("keydown", this.onTextareaKeydown);
  }

  isActive() {
    return this.formTarget.classList.contains("active");
  }

  setInactive() {
    this.textareaTarget.blur();
    this.formTarget.classList.remove("active");
  }

  // Add the "active" class to the form and focus the textarea with the cursor at the end
  setActive() {
    this.formTarget.classList.add("active");
    this.textareaTarget.focus();
    this.toggleButtonTarget.scrollIntoView({ behavior: "smooth" });
    this.textareaTarget.setSelectionRange(
      this.textareaTarget.value.length,
      this.textareaTarget.value.length
    );
  }

  // Stop propagation of the click event so that the window click handler doesn't hide the form
  toggle(e) {
    e.stopPropagation();

    if (this.isActive()) {
      this.setInactive();
    } else {
      this.setActive();
    }
  }

  // Hide the form when the user clicks outside of it
  onWindowClick = (e) => {
    const mentionsElement = document.querySelector(".mentions");
    const shouldIgnoreClick =
      mentionsElement || !this.isActive() || this.formTarget.contains(e.target);

    if (shouldIgnoreClick) return;

    this.setInactive();
  };

  // Disable the textarea when the form is being submitted
  onFormSubmit = (e) => {
    this.textareaTarget.disabled = true;
  };

  // When the form is submitted, clear the textarea and re-enable it
  onFormSubmitEnd = (e) => {
    this.textareaTarget.disabled = false;
    if (!e.detail.success) return;

    this.resetImagePreview();
    this.formTarget.reset();
    this.dispatchTextareaInputEvent();
    this.setInactive();
  };

  resetImagePreview() {
    if (!this.hasImagePreviewTarget) {
      return;
    }

    this.imagePreviewTarget.innerHTML = "";
    this.onFileRemoved();
  }

  // Submit the form when the user presses ⌘+Enter or Ctrl+Enter
  onTextareaKeydown = (e) => {
    if (e.code === "Enter" && (e.metaKey || e.ctrlKey)) {
      this.formTarget.requestSubmit();
    }
  };

  onFileAdded = (event) => {
    this.textareaTarget.removeAttribute("data-form-target");
    this.dispatchTextareaInputEvent();
    this.element.classList.add("has-image-attachment");
  };

  onFileRemoved = (event) => {
    this.textareaTarget.setAttribute("data-form-target", "requiredInput");
    this.dispatchTextareaInputEvent();
    this.element.classList.remove("has-image-attachment");
  };

  dispatchTextareaInputEvent() {
    this.textareaTarget.dispatchEvent(new Event("input", { bubbles: true }));
  }

  // Clean up event listeners when the controller is disconnected
  disconnect() {
    window.removeEventListener("click", this.onWindowClick);
    this.formTarget.removeEventListener(
      "direct-upload:fileAdded",
      this.onFileAdded
    );
    this.formTarget.removeEventListener(
      "direct-upload:fileRemoved",
      this.onFileRemoved
    );
    this.formTarget.removeEventListener(
      "turbo:submit-start",
      this.onFormSubmit
    );
    this.formTarget.removeEventListener(
      "turbo:submit-end",
      this.onFormSubmitEnd
    );
    this.textareaTarget.removeEventListener("keydown", this.onTextareaKeydown);
  }
}
