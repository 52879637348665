import { Controller } from "@hotwired/stimulus";
import { buildSpinner } from "./helpers";

// Connects to data-controller="sms-verification"
export default class extends Controller {
  static targets = [
    "form",
    "codeInput",
    "completeCode",
    "status",
    "resendButton",
  ];

  connect() {
    this.codeLength = this.codeInputTargets.length;
  }

  // This is called on first page load and whenever the form is reloaded due to failed verification
  formTargetConnected() {
    this.formTarget.addEventListener("paste", this.onPaste.bind(this));
    this.formTarget.addEventListener(
      "turbo:submit-start",
      this.handleSubmitStart.bind(this)
    );
    this.formTarget.addEventListener(
      "turbo:submit-end",
      this.handleSubmitEnd.bind(this)
    );

    this.codeInputTargets[0].focus();
    this.element.setAttribute("data-verification-controller-initialized", true);
  }

  getCodeInputIndex(element) {
    return parseInt(element.getAttribute("data-code-input-index"));
  }

  onCodeInput(event) {
    const code = event.target.value;
    const currentInputIndex = this.getCodeInputIndex(event.target);

    if (code.length === 1) {
      this.focusNextInput(currentInputIndex);
    } else if (code.length > 1) {
      event.target.value = code[code.length - 1];
      this.focusNextInput(currentInputIndex);
    } else if (code.length === 0) {
      this.focusPreviousInput(currentInputIndex);
    }
  }

  // If paste event contains a string with a length equal to the number of code inputs,
  // set the value of each code input to the corresponding character in the string
  onPaste(event) {
    event.preventDefault();
    const code = event.clipboardData.getData("text");
    if (code.length !== this.codeLength) return;

    this.codeInputTargets.forEach((codeInput, index) => {
      codeInput.value = code[index];
    });

    this.requestSubmit();
  }

  // Focus next input, or submit form if it's the last input
  focusNextInput(index) {
    if (index < this.codeLength - 1) {
      this.codeInputTargets[index + 1].focus();
    } else if (index === this.codeLength - 1) {
      this.requestSubmit();
    }
  }

  // Focus previous input, unless it's the first input
  focusPreviousInput(index) {
    if (index > 0) {
      this.codeInputTargets[index - 1].focus();
    }
  }

  // Handle navigating with arrow keys, backspace, delete, and enter
  onKeydown(event) {
    const currentInputIndex = this.getCodeInputIndex(event.target);
    switch (event.key) {
      case "Backspace":
      case "Delete":
        event.preventDefault();
        this.codeInputTargets[currentInputIndex].value = "";
        this.focusPreviousInput(currentInputIndex);
      case "ArrowLeft":
        this.focusPreviousInput(currentInputIndex);
        break;
      case "ArrowRight":
        this.focusNextInput(currentInputIndex);
        break;
      case "Enter":
        this.requestSubmit();
        break;
    }
  }

  // Set hidden input value to the value of all code inputs
  // Submit form if all code inputs are filled
  requestSubmit() {
    const code = this.codeInputTargets
      .map((codeInput) => codeInput.value)
      .join("");
    this.completeCodeTarget.value = code;

    if (code.length !== this.codeLength) return;

    this.formTarget.requestSubmit();
  }

  // Disable inputs, clear errors, and show spinner
  handleSubmitStart(event) {
    this.codeInputTargets.forEach((codeInput) => {
      codeInput.disabled = true;
    });
    this.spinner = buildSpinner();
    this.statusTarget.innerHTML = "";
    this.statusTarget.appendChild(this.spinner);
  }

  // Re-enable inputs, remove spinner (errors will be shown by turbo)
  handleSubmitEnd(event) {
    this.codeInputTargets.forEach((codeInput) => {
      codeInput.disabled = false;
    });
    this.spinner.remove();
  }

  resendButtonTargetConnected() {
    this.resendButtonTarget.form.addEventListener(
      "turbo:submit-start",
      this.handleResendSubmitStart.bind(this)
    );
    this.resendButtonTarget.form.addEventListener(
      "turbo:submit-end",
      this.handleResendSubmitEnd.bind(this)
    );
    this.setResendTimer();
  }

  handleResendSubmitStart(event) {
    this.resendButtonTarget.disabled = true;
    this.statusTarget.innerHTML = "";
    this.spinner = buildSpinner();
    this.statusTarget.appendChild(this.spinner);
  }

  handleResendSubmitEnd(event) {
    this.spinner.remove();

    if (!event.detail.success) return;

    this.focusPreviousInput(1); // Focus first input
    this.statusTarget.classList.add("resend-success");
  }

  setResendTimer() {
    if (this.resendButtonTarget.disabled !== true) return;

    const buttonText = this.resendButtonTarget.textContent;
    let countdown = this.resendButtonTarget.getAttribute("data-countdown");

    this.resendInterval = setInterval(() => {
      countdown -= 1;
      this.resendButtonTarget.textContent = `${buttonText} (${countdown})`;
      if (countdown > 0) return;

      clearInterval(this.resendInterval);
      this.resendButtonTarget.disabled = false;
      this.resendButtonTarget.textContent = buttonText;
    }, 1000);
  }
}
