import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";
import { isDesktop } from "./helpers";

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ["button", "menu"];

  connect = () => {
    if (!this.hasButtonTarget || !this.hasMenuTarget) return;

    this.onResize();
    this.onResize = throttle(this.onResize, 200);
    window.addEventListener("resize", this.onResize);
  };

  isOpen = () => {
    return this.menuTarget.ariaHidden === "false";
  };

  userOpened = () => {
    return this.menuTarget.dataset.userOpened === "true";
  };

  onResize = () => {
    // Ensure proper aria attributes are set when resizing between mobile and desktop
    if (isDesktop() && !this.isOpen()) {
      this.open();
    } else if (!isDesktop() && this.isOpen() && !this.userOpened()) {
      this.hideMenu();
    }
  };

  onKeyup = (event) => {
    if (event.key !== "Escape") return;

    this.close();
  };

  onClick = (event) => {
    if (event.target !== this.menuTarget) return;

    this.close();
  };

  onSubmitEnd = (event) => {
    if (!event.detail.success) {
      return;
    }

    this.close();
  };

  open = (event) => {
    if (event && event.target === this.buttonTarget) {
      this.menuTarget.setAttribute("data-user-opened", "true");
    }

    this.buttonTarget.setAttribute("aria-expanded", "true");
    this.menuTarget.setAttribute("aria-hidden", "false");

    if (isDesktop()) return;

    window.addEventListener("keyup", this.onKeyup);
    window.addEventListener("click", this.onClick);
    this.menuTarget.addEventListener("turbo:submit-end", this.onSubmitEnd);
  };

  close = () => {
    if (isDesktop()) return;

    this.menuTarget.classList.add("animate__fadeOutUp");
    this.menuTarget.addEventListener("animationend", this.hideMenu);
    this.menuTarget.removeAttribute("data-user-opened");
    window.removeEventListener("keyup", this.onKeyup);
    window.removeEventListener("click", this.onClick);
    this.menuTarget.removeEventListener("turbo:submit-end", this.onSubmitEnd);
  };

  hideMenu = () => {
    this.buttonTarget.setAttribute("aria-expanded", "false");
    this.menuTarget.setAttribute("aria-hidden", "true");
    this.menuTarget.classList.remove("animate__fadeOutUp");
    this.menuTarget.removeEventListener("animationend", this.hideMenu);
  };

  disconnect = () => {
    window.removeEventListener("resize", this.onResize);
  };
}
