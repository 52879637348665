import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reply"
export default class extends Controller {
  static targets = ["container", "textarea", "toggle", "form", "imagePreview"];

  connect() {
    if (!this.hasFormTarget) {
      return;
    }

    this.addEventListeners();
    this.originalToggleText = this.toggleTarget.textContent;
    this.initialValue = this.textareaTarget.value;
    this.active = false;
  }

  addEventListeners() {
    this.formTarget.addEventListener("turbo:submit-start", this.onFormSubmit);
    this.formTarget.addEventListener("turbo:submit-end", this.onFormSubmitEnd);
    this.textareaTarget.addEventListener("keydown", this.onTextareaKeydown);
    this.formTarget.addEventListener(
      "direct-upload:fileAdded",
      this.onFileAdded
    );
    this.formTarget.addEventListener(
      "direct-upload:fileRemoved",
      this.onFileRemoved
    );
  }

  onFileAdded = (event) => {
    this.textareaTarget.removeAttribute("data-form-target");
    this.dispatchTextareaInputEvent();
    this.element.classList.add("has-image-attachment");
  };

  onFileRemoved = (event) => {
    this.textareaTarget.setAttribute("data-form-target", "requiredInput");
    this.dispatchTextareaInputEvent();
    this.element.classList.remove("has-image-attachment");
  };

  onTextareaKeydown = (e) => {
    if (e.code === "Enter" && (e.metaKey || e.ctrlKey)) {
      this.formTarget.requestSubmit();
    }
  };

  onFormSubmit = (e) => {
    this.textareaTarget.disabled = true;
  };

  onFormSubmitEnd = (e) => {
    this.textareaTarget.disabled = false;
    if (!e.detail.success) return;

    this.textareaTarget.value = this.initialValue;
    this.dispatchTextareaInputEvent();
    this.hideReplyField();
    this.resetImagePreview();
  };

  resetImagePreview() {
    if (!this.hasImagePreviewTarget) {
      return;
    }

    this.imagePreviewTarget.innerHTML = "";
    this.onFileRemoved();
  }

  toggleReplyField() {
    if (this.active) {
      this.hideReplyField();
    } else {
      this.showReplyField();
    }

    this.active = !this.active;
  }

  onReplySubmitEnd = (event) => {
    if (!event.detail.success) return;

    this.hideReplyField();
  };

  showReplyField() {
    this.containerTarget.setAttribute("aria-hidden", false);
    this.toggleTarget.setAttribute("aria-expanded", true);
    this.toggleTarget.textContent = "Cancel";
    this.textareaTarget.focus();
    const { value } = this.textareaTarget;
    this.textareaTarget.setSelectionRange(value.length, value.length);
  }

  hideReplyField() {
    this.containerTarget.setAttribute("aria-hidden", true);
    this.toggleTarget.setAttribute("aria-expanded", false);
    this.toggleTarget.textContent = this.originalToggleText;
  }

  dispatchTextareaInputEvent() {
    this.textareaTarget.dispatchEvent(new Event("input", { bubbles: true }));
  }

  disconnect() {
    this.formTarget.removeEventListener(
      "turbo:submit-start",
      this.onFormSubmit
    );
    this.formTarget.removeEventListener(
      "turbo:submit-end",
      this.onFormSubmitEnd
    );
    this.textareaTarget.removeEventListener("keydown", this.onTextareaKeydown);
    this.formTarget.removeEventListener(
      "direct-upload:fileAdded",
      this.onFileAdded
    );
    this.formTarget.removeEventListener(
      "direct-upload:fileRemoved",
      this.onFileRemoved
    );
  }
}
