import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="forms--notched-text-field-component"
export default class extends Controller {
  static targets = ["input"];

  toggleVisibility() {
    this.element.classList.toggle("visibility-on");
    const inputType =
      this.inputTarget.type === "password" ? "text" : "password";
    this.inputTarget.type = inputType;
    this.inputTarget.focus();
  }

  formatPhoneNumber() {
    const phoneNumber = this.inputTarget.value;
    // Regex to format phone number as (xxx)xxx-xxxx
    const formattedPhoneNumber = phoneNumber.replace(
      /(\d{3})(\d{3})(\d{4})/,
      "($1) $2-$3"
    );
    this.inputTarget.value = formattedPhoneNumber;
  }
}
