import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="copy"
export default class extends Controller {
  static values = {
    source: String,
    timeout: { type: Number, default: 10000 },
  };

  copyToClipboard() {
    navigator.clipboard.writeText(this.sourceValue);
    this.element.classList.add("copied");

    setTimeout(() => {
      this.element.classList.remove("copied");
    }, this.timeoutValue);
  }
}
