import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

// Connects to data-controller="truncate-rows"
export default class extends Controller {
  static values = {
    maxRows: { type: Number, default: 2 }, // Max rows to show before truncating
  };

  static targets = [
    "button", // Button to show/hide all items
    "container", // Immediate parent of items
    "item", // Individual items to truncate based on maxRows
  ];

  connect() {
    if (this.itemTargets.length === 0) {
      return;
    }

    this.onResize = throttle(this.onResize.bind(this), 100);
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  onResize = () => {
    this.updateTruncation();
  };

  getColumnGap(element) {
    const computedStyle = window.getComputedStyle(element);
    const columnGap = computedStyle.getPropertyValue("column-gap");
    const columnGapValue = parseInt(columnGap, 10);
    return columnGapValue;
  }

  getItemsPerRow(element) {
    const itemWidth = this.itemTargets[0].offsetWidth;
    const calculatedGap = this.getColumnGap(element);
    const containerWidth = element.offsetWidth;
    const itemsPerRow = Math.floor(containerWidth / (itemWidth + calculatedGap));
    return itemsPerRow;
  }

  updateItemClasses(maxItems) {
    this.itemTargets.forEach((item, index) => {
      const hideItem = index >= maxItems;
      item.classList.toggle("truncated", hideItem);
    });
  }

  updateButtonClasses(maxItems) {
    const expanded = maxItems >= this.itemTargets.length;
    this.buttonTarget.classList.toggle("expanded", expanded);
  }

  updateTruncation() {
    const itemsPerRow = this.getItemsPerRow(this.containerTarget);
    const maxItems = itemsPerRow * this.maxRowsValue;
    this.updateItemClasses(maxItems);
    this.updateButtonClasses(maxItems);
  }

  expand() {
    const maxItems = this.itemTargets.length;
    this.updateItemClasses(maxItems);
    this.updateButtonClasses(maxItems);
  }

  disconnect() {
    window.removeEventListener("resize", this.onResize);
  }
}
