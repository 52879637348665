import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { isDesktop } from "./helpers";

// Connects to data-controller="user-tier-popup"
export default class extends Controller {
  static targets = ["container", "popup"];

  connect() {
    this.onNameMouseLeave = debounce(this.onNameMouseLeave, 200);
  }

  show = (event) => {
    event.stopPropagation();
    this.containerTarget.setAttribute("aria-hidden", "false");
    document.addEventListener("click", this.onClickOutside);

    if (isDesktop()) {
      return;
    }

    this.animateIn();
  };

  animateIn = () => {
    this.containerTarget.classList.add("animate__animated", "animate__fadeIn");
    this.popupTarget.classList.add("animate__animated", "animate__fadeInUp");
  };

  onClickOutside = (event) => {
    if (this.popupTarget.contains(event.target)) {
      return;
    }

    this.hide(event);
  };

  hide = (event) => {
    document.removeEventListener("click", this.onClickOutside);

    this.containerTarget.classList.remove("animate__fadeIn");
    this.popupTarget.classList.remove("animate__fadeInUp");

    if (isDesktop()) {
      this.containerTarget.setAttribute("aria-hidden", "true");
    } else {
      this.animateOut();
    }
  };

  onAnimationOutEnd = (event) => {
    this.containerTarget.setAttribute("aria-hidden", "true");
    this.containerTarget.classList.remove("animate__fadeOut");
    this.popupTarget.classList.remove("animate__fadeOutDown");
  };

  animateOut = () => {
    this.popupTarget.addEventListener("animationend", this.onAnimationOutEnd, {
      once: true,
    });

    this.containerTarget.classList.add("animate__animated", "animate__fadeOut");
    this.popupTarget.classList.add("animate__animated", "animate__fadeOutDown");
  };

  onNameMouseLeave = (event) => {
    if (!isDesktop() || this.popupTarget.contains(event.relatedTarget)) {
      return;
    }

    this.hide(event);
  };
}
