import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="removals"
export default class extends Controller {
  remove(event) {
    if (event.target != this.element) return;

    this.element.remove();
  }
}
